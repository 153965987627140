@import "../../assets/styles/utils";

*{
  &::-webkit-scrollbar { 
    @include res(width,0);  
  }
}
.wow{
  @include res(visibility,visible !important,(md:hidden));
}

.section{
  @include res(overflow,hidden,(md:visible));
  @include res(height,100vh,(md:auto));
}

.index-common-title{
  color: #000;
  position: relative;
  white-space: nowrap;
  &.white{
    color: #fff;
  }
  &::after{
    content: '';
    display: block;
    background: url(../../assets/images/title_bg.jpg) no-repeat;
    position: absolute;
    background-size: contain;
    @include res(width,12px, 8 / 12);
    @include res(height,24px, 14 / 24);
    @include res(top,25px, 10 / 25);
    @include res(left,-28px, -14 / -28);
  }
  h1{
    @include res(letter-spacing,5px, 2 / 5);
    @include res(font-size,48px, 22 / 48);
    @include res(font-weight,null,(sm:bold));
    @media screen and(max-width:1366px) {
      @include res(font-size,40px, 24 / 40);
    }
  }
  h3{
    text-transform: uppercase;
    @include res(letter-spacing,2px, 1 / 2);
    @include res(font-size, 21px, 16 / 21);
  }
}

.index-banner{
  overflow: hidden;
  position: relative;
  @include res(height,100vh);
  .swiper{
    height: 100%;
    overflow: hidden;
    .swiper-slide{
      overflow: hidden;
      position: relative;
      &.ani-slide{
        b{
          transform: scale(1);
        }
      }
      // &::after{
      //   content: '';
      //   display: block;
      //   width: 100%;
      //   height: 100%;
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   z-index: 2;
      //   background-color: rgba(0,0,0,.5);
      // }
      .video{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        @include res(display,block,(md:none));
        video{
          width: 100%;
          height: 100%;
          transform: scale(1.2);
          transform-origin: center;
        }
      }
      b{
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transform-origin: center;
        background-size: cover;
        background-position: center;
        transform: scale(1.1);
        transition: all 5s;
        z-index: 0;
        &.pc-img{
          @include res(display,block);
        }
        &.mob-img{
          @include res(display,none);
        }
      }
    }
    .swiper-pagination{
      text-align: left;
      @include res(padding-left, 160px, 20 / 160);
      @include res(padding-right, 160px, 20 / 160);
      @include res(bottom,80px, 30 / 80);
      .swiper-pagination-bullet{
        opacity: 1;
        background: transparent;
        position: relative;
        box-sizing: border-box;
        margin-left: 0;
        transition: all .3s;
        border: 1px solid transparent;
        @include res(margin-right,20px, 10 / 20);
        @include res(width,50px, (md:44px,sm:40px));
        @include res(height,50px, (md:44px,sm:40px));
        &:hover{
            &::after{
              transform: translateX(-50%) translateY(-50%) scale(1.4);
            }
        }
        &::after{
            content: '';
            display: block;
            background: rgba(255,255,255,.6);
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transition: all .3s;
            transform: translateX(-50%) translateY(-50%);
            @include res(width, 10px, 8 / 10);
            @include res(height, 10px, 8 / 10);
        }
        &.swiper-pagination-bullet-active{
          border: 1px solid rgba(255,255,255,.2);
          svg{
            opacity: 1;
            circle{
              animation:6s circle linear;
              @include res(animation-name,circle,(md:circle2,sm:circle3));
            }
          }
        }
        svg{
          position: absolute;
          top: -1px;
          left: -1px;
          z-index: 1;
          transform-origin: center;
          transform-box: fill-box;
          transform: rotate(-90deg);
          transition: all .3s;
          opacity: 0;
          @include res(width,50px,(md:44px,sm:40px));
          @include res(height,50px,(md:44px,sm:40px));
          circle {
              stroke-width: 1;
              fill: none;
              stroke: #fff;
              @include res(stroke-dasharray,151px,(md:132px,sm:120px));
              @include res(stroke-dashoffset,151px,(md:132px,sm:120px));
              @include res(cx,25px,(md:22px,sm:20px));
              @include res(cy,25px,(md:22px,sm:20px));
              @include res(r,24px,(md:21px,sm:19px));
          }
        }
      }
    }
  }
  .banner-t{
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    z-index: 2;
    transform: translateY(-50%);
    @include res(padding-left, 160px, 20 / 160);
    @include res(padding-right, 160px, 20 / 160);
    &.active{
      h1,h2,.link{
        opacity: 1;
        transform: translateY(0%);
      }
    }
    h1{
      color: #fff;
      line-height: 1;
      transition: all .5s;
      opacity: 0;
      transform: translateY(10%);
      @include res(letter-spacing,3px, 1 / 3);
      @include res(font-size,88px, 26 / 88);
      @include res(margin-bottom,45px, 20 / 45);
      @media screen and(max-width:1366px) {
        @include res(font-size,60px, 26 / 60);
      }
    }
    h2{
      color: #fff;
      line-height: 1;
      opacity: .4;
      font-weight: lighter;
      transition: all .5s;
      opacity: 0;
      transform: translateY(10%);
      transition-delay: .2s;
      @include res(margin-bottom,80px, 18 / 80);
      @include res(font-size,38px, 18 / 38);
      @media screen and(max-width:1366px) {
        @include res(font-size,30px, 16 / 30);
      }
    }
    .link{
      transition: all .5s;
      opacity: 0;
      transform: translateY(10%);
      transition-delay: .4s;
    }
  }
  .scroll-icon{
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    white-space: nowrap;
    z-index: 2;
    text-align: center;
    @include res(display,block,(md:none));
    .icon{
      position: relative;
      border: 1px solid #fff;
      border-radius: 20px;
      @include res(margin-bottom,25px, 15 / 25);
      @include res(width,24px);
      @include res(height,40px);
      &::after{
        content: '';
        display: block;
        width: 2px;
        height: 7px;
        background-color: #fff;
        position: absolute;
        top: 10%;
        left: calc(50% - 1px);
        animation: rain 1.3s infinite;
      }
    }
    .t{
      writing-mode:tb-rl;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: lighter;
      opacity: .4;
      font-weight: lighter;
      letter-spacing: 2px;
    }
  }
}

.section2{
  position: relative;
  background: url(./images/index_dian_bg1.png) no-repeat 40% top;
  @include res(padding-bottom,0,(md:100px,sm:60px,xs:40px));
  &::after{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: url(./images/index_dian_bg1.png) no-repeat 40% top #fff;
  }
  &.active{
    .cont{
      .swiper-cont{
        .pro-swiper,.trade-swiper{
          .left{
            &::after{
              transform: scaleX(0);
              
            }
          }
          .right{
            .swiper{
              opacity: 1;
              transform:translateY(0);
              transition-delay: .2s;
            }
          }
        }
      }
    }
  }
  .mycontainer{
    position: relative;
    z-index: 2;
  }
  .cont{
    position: relative;
    @include res(margin-top,10vh,(md:100px,sm:60px,xs:40px));
    .section2-title{
      top: 0;
      left: 0;
      z-index: 2;
      @include res(position,absolute,(sm:relative));
      @include res(width,53.12%,(sm:100%));
      @include res(margin-bottom,0,(xs:20px));
      .swiper-tab{
        top: 50%;
        right: 0;
        @extend %clearfix;
        @include res(position,absolute,(xs:static));
        @include res(transform,translateY(-50%),(xs:translateY(0%)));
        @include res(margin-top,0,(xs:10px));
        div{
          cursor: pointer;
          position: relative;
          border-radius: 50px;
          overflow: hidden;
          z-index: 1;
          transition: all .4s $anime-bezier;
          @include res(padding-left,22px, 15 / 22);
          @include res(padding-right,22px, 15 / 22);
          @include res(padding-top,6px, 3 / 6);
          @include res(padding-bottom,6px, 3 / 6);
          @include res(font-size, 21px, 16 / 21);
          @include res(float,left);
          @include res(margin-right,15px, 10 / 15);
          &:hover{
            color: $color-main;
          }
          &.active{
            color: #fff;
            &::after{
              transform: translateY(0%);
            }
          }
          &::after{
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-image: linear-gradient(to bottom, #0091ff, #0056ff);
            position: absolute;
            z-index: -1;
            transition: all .4s $anime-bezier;
            transform-origin: center;
            transform: translateY(101%);
          }
        }
      }
    }
    .swiper-cont{
      .pro-swiper,.trade-swiper{
        box-sizing: border-box;
        position: relative;
        @include res(padding-left,53.12%,(xs:0));
        .left{
          position: absolute;
          bottom: 0;
          left: 0;
          overflow: hidden;
          @include res(border-radius,0,(xs:20px));
          @include res(height,75.75%,(xs:100%));
          @include res(width,53.12%,(xs:100%));
          &::after{
            content: '';
            width: 100%;
            height: 100%;
            background-color: #fff;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            transform-origin: right;
            transition: all .6s $anime-bezier;
            transition-delay: .2s;
            @include res(display,block,(md:none));
          }
          .swiper{
            height: 100%;
            overflow: hidden;
            .swiper-slide{
              position: relative;
              @include res(border-radius,0,(xs:20px));
              b{
                width: 100%;
                height: 100%;
                display: block;
                background-position: center;
                background-size: cover;
              }
            }
          }
        }
        .right{
          box-sizing: border-box;
          border-top-right-radius: 20px;
          border-top-left-radius: 20px;
          border-bottom-right-radius: 20px;
          position: relative;
          z-index: 2;
          @include res(background-color,rgba(0, 84, 255,1),(xs:rgba(0,84,255,.5)));
          @include res(border-bottom-left-radius,0,(xs:20px));
          @include res(padding-top,70px, 20 / 70);
          @include res(padding-bottom,70px, 20 / 70);
          @include res(padding-left,80px, 20 / 80);
          @include res(padding-right,80px, 20 / 80);
          @include res(height,75vh,(md:520px,sm:460px,xs:380px));
          @media (max-height: 800px){
            @include res(padding-top,22px, 20 / 22);
            @include res(padding-bottom,22px, 20 / 22);
            @include res(padding-left,60px, 20 / 60);
            @include res(padding-right,60px, 20 / 60);
          }
          // padding: 22px 60px;
          .swiper{
            height: 100%;
            position: relative;
            overflow: hidden;
            transition: all .6s;
            @include res(opacity,0,(md:1));
            @include res(transform,translateY(10%),(md:translateY(0%)));
            @include res(padding-bottom,30px, 20 / 30);
            .swiper-slide{
              color: #fff;
              &.swiper-slide-active{
                >div{
                  opacity: 1;
                  transform: translateY(0);
                }
              }
              >div{
                opacity: 0;
                transform: translateY(10%);
                transition: all .6s;
              }
              .num{
                line-height: 1;
                @include res(font-size, 100px, 40 / 100);
                @include res(margin-bottom,40px, 20 / 40);
                @media screen and(max-width:1366px){
                  @include res(font-size, 70px, 30 / 70);
                  @include res(margin-bottom,20px);
                }
                @media (max-height: 800px){
                  @include res(margin-bottom,20px);
                  @include res(font-size, 70px, 30 / 70);
                }
              }
              .title{
                @include res(font-size, 36px, 18 / 36);
                @include res(margin-bottom,25px, 10 / 25);
                @media screen and(max-width:1366px){
                  @include res(font-size, 30px, 18 / 30);
                  @include res(margin-bottom,10px);
                }
                @media (max-height: 800px){
                  @include res(margin-bottom,10px);
                  @include res(font-size, 30px, 18 / 30);
                }
              }
              .desc{
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 4;
                overflow: hidden;
                @include res(font-size, 18px, 14 / 18);
                @include res(margin-bottom,50px, 20 / 50);
                @media screen and(max-width:1366px){
                  @include res(margin-bottom,30px, 20 / 30);
                }
                @media (max-height: 800px){
                  @include res(margin-bottom,30px, 20 / 30);
                }
              }
            }
            .swiper-contral{
              position: absolute;
              left: 0;
              bottom: 0;
              width: 100%;
              z-index: 2;
              .swiper-btn{
                @include res(width,20%);
                @include res(float,right);
                @extend %clearfix;
                .swiper-button-prev,.swiper-button-next{
                  position: static;
                  background-image: none;
                  width: auto;
                  height: auto;
                  margin-top: 0;
                  transition: all .3s;
                  line-height: 1;
                  &::after{
                    font-family: 'iconfont';
                    color: #fff;
                    
                    @include res(font-size,36px, 20 / 36);
                  }
                }
                .swiper-button-prev{
                  float: left;
                  &:hover{
                    transform: translateX(-5px);
                  }
                  &::after{
                    content: '\e625';
                  }
                }
                .swiper-button-next{
                  float: right;
                  &:hover{
                    transform: translateX(5px);
                  }
                  &::after{
                    content: '\e624';
                  }
                }
              }
              .swiper-page{
                position: absolute;
                box-sizing: border-box;
                left: 0;
                top: 50%;
                @include res(float,left);
                @include res(width,65%);
                @include res(padding-left,44px, 30 / 44);
                @include res(padding-right,44px, 30 / 44);
                .swiper-pagination{
                  width: 100%;
                  height: 1px;
                  position: static;
                  background: rgba(255,255,255,.2);
                  .swiper-pagination-progressbar-fill{
                    height: 2px;
                    background: #4773ff;
                    margin-top: -1px;
                  }
                }
                .active-num,.total{
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  color: #fff;
                  @include res(font-size, 20px, 16 / 20);
                }
                .active-num{
                  left: 0;
                }
                .total{
                  right: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

.section3{
  background-color: #0b0c45;
  position: relative;
  overflow: hidden;
  @include res(padding-top,0,(md:70px,sm:30px));
  @include res(padding-bottom,0,(md:100px,sm:40px));
  &.active{
    .mycontainer{
      .left{
        .index-common-title{
          opacity: 1;
          transform: translateY(0);
          transition-delay: .3s;
        }
        .text{
          h2,.desc,.link{
            opacity: 1;
            transform: translateY(0);
          }
          h2{transition-delay: .4s;}
          .desc{transition-delay: .5s;}
          .link{transition-delay: .6s;}
        }
      }
    }
  }
  .bg-img{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    animation: rotate 100s linear infinite;
    @include res(display,block);
    @include res(background-size,contain,(xs:200% auto));
  }
  .mycontainer{
    position: relative;
    z-index: 1;
    .left{
      @include res(width,42.5%,(xs:100%));
      @include res(margin-top,50px,10 / 50);
      .index-common-title{
        transition: all .5s;
        @include res(opacity,0,(md:1));
        @include res(transform,translateY(20%),(md:translateY(0%)));
      }
      .text{
        color: #fff;
        @include res(max-width,380px);
        @include res(margin-top,70px, 20 / 70);
        @include res(margin-bottom,80px, 20 / 80);
        @media screen and(max-width:1366px) {
          @include res(margin-top,50px, 20 / 50);
          @include res(margin-bottom,50px, 20 / 50);
        }
        h2,.link{
          transition: all .5s;
          @include res(opacity,0,(md:1));
          @include res(transform,translateY(20%),(md:translateY(0%)));
        }
        h2{
          @include res(font-size,36px, 18 / 36);
          @include res(margin-bottom,25px, 10 / 25);
        }
        .desc{
          transition: all .5s;
          @include res(opacity,0,(md:.7));
          @include res(transform,translateY(10%),(md:translateY(0%)));
          @include res(margin-bottom,40px, 10 / 40);
        }
      }
      .swiper-contral{
        position: relative;
        @extend %clearfix;
        .swiper-btn{
          @include res(width,20%);
          @include res(float,right);
          @extend %clearfix;
          .swiper-button-prev,.swiper-button-next{
            position: static;
            background-image: none;
            width: auto;
            height: auto;
            margin-top: 0;
            transition: all .3s;
            line-height: 1;
            &::after{
              font-family: 'iconfont';
              color: #fff;
              
              @include res(font-size,36px, 20 / 36);
            }
          }
          .swiper-button-prev{
            float: left;
            &:hover{
              transform: translateX(-5px);
            }
            &::after{
              content: '\e625';
            }
          }
          .swiper-button-next{
            float: right;
            &:hover{
              transform: translateX(5px);
            }
            &::after{
              content: '\e624';
            }
          }
        }
        .swiper-page{
          position: absolute;
          box-sizing: border-box;
          left: 0;
          top: 50%;
          @include res(float,left);
          @include res(width,65%);
          @include res(padding-left,44px, 30 / 44);
          @include res(padding-right,44px, 30 / 44);
          .swiper-pagination{
            width: 100%;
            height: 1px;
            position: static;
            background: rgba(255,255,255,.2);
            .swiper-pagination-progressbar-fill{
              height: 2px;
              background: #0295ff;
              margin-top: -1px;
            }
          }
          .active-num,.total{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            color: #fff;
            @include res(font-size, 20px, 16 / 20);
          }
          .active-num{
            left: 0;
          }
          .total{
            right: 0;
            opacity: .3;
          }
        }
      }
    }
  }
  .right{
    z-index: 1;
    overflow: hidden;
    @include res(margin-top,0,(xs:30px));
    @include res(position,absolute,(xs:static));
    @include res(width,44%,(md:47%,sm:50%,xs:100%));
    @include res(left,56%,(md:53%,sm:50%));
    @include res(top,12vh,(md:0%));
    @include res(height,80vh,(md:100%,xs:auto));
    .swiper{
      // overflow: hidden;
      height: 100%;
      .swiper-slide{
        &.prev{
          .item{
            opacity: .8;
            transform: scale(.85) translateX(40%);
          }
        }
        &.active{
          .item{
            opacity: 1;
            transform: scale(1) translateX(60%);
          }
        }
        .item{
          background:url(./images/index_icon_bg2.png) no-repeat center;
          background-size: 100% 100%;
          position: relative;
          box-sizing: border-box;
          transform-origin: left center;
          transition: all .5s;
          @include res(opacity,.4,(xs:1));
          @include res(transform,scale(.7),(xs:scale(1)));
          @include res(padding-top,25px, 15 / 25);
          @include res(padding-left,70px, 40 / 70);
          @include res(width,381px, 220 / 381);
          @include res(height,137px, 80 / 137);
          @include res(margin-left,44px, 40 / 44);
          @media screen and(max-width:1366px) {
            @include res(padding-top,15px);
            @include res(padding-left,70px, 40 / 70);
            @include res(width,320px, 220 / 320);
            @include res(height,110px, 80 / 110);
            @include res(margin-left,30px, 40 / 30);
          }
          .item-icon{
            background:url(./images/index_icon_bg.png) no-repeat center;
            background-size: contain;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            @include res(left, -44px, - 20 / -44);
            @include res(width,100px, 50 / 100);
            @include res(height,100px, 50 / 100);
            @media screen and(max-width:1366px) {
              @include res(left, -35px, - 20 / -35);
              @include res(width,80px, 50 / 80);
              @include res(height,80px, 50 / 80);
            }
            img{
              display: block;
              width: 100%;
              height: auto;
            }
          }
          .item-t{
            white-space: nowrap;
            @include res(letter-spacing, 3px, 1 / 3);
            .en{
              font-weight: lighter;
              text-transform: uppercase;
              color: rgba(255,255,255,.5);
              @include res(font-size, 22px, 14 / 22);
            }
            .cn{
              color: rgba(255,255,255,1);
              @include res(font-size, 26px, 16 / 26);
            }
          }
        }
      }
    }
  }
}

.section4{
  position: relative;
  @include res(padding-bottom,0,(md:80px,sm:50px,xs:40px));
  &::after{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  &.active{
    .mycontainer{
      .left .index-common-title{
        opacity: 1;
        transform: translateY(0);
        transition-delay: .3s;
      }
      .right .swiper .swiper-slide{
        opacity: 1;
        transform: translateX(0);
        &:nth-child(1){
          transition-delay: .3s;
        }
        &:nth-child(2){
          transition-delay: .4s;
        }
      }
    }
  }
  .mycontainer{
    position: relative;
    z-index: 2;
    @include res(padding-top,0,(mmd:70px,sm:50px,xs:30px));
    @extend %clearfix;
    .left{
      @extend %clearfix;
      @include res(width,16.8%,(xs:100%));
      @include res(float,left,(xs:none));
      @include res(margin-bottom,0,(xs:20px));
      .index-common-title{
        transition: all .5s;
        @include res(opacity,0,(md:1));
        @include res(transform,translateY(20%),(md:translateY(0%)));
        @include res(float,none,(xs:left));
      }
      .swiper-btn{
        @extend %clearfix;
        @include res(width,125px,(xs:20%));
        @include res(float,none,(xs:right));
        @include res(margin-top,300px, 60 / 300);
        @media screen and(max-width:767px){
          margin-top: 40px;
        }
        .swiper-button-prev,.swiper-button-next{
          position: static;
          background-image: none;
          width: auto;
          height: auto;
          margin-top: 0;
          transition: all .3s;
          line-height: 1;
          &::after{
            font-family: 'iconfont';
            color: $color-main-light;
            @include res(font-size,36px, 20 / 36);
          }
        }
        .swiper-button-prev{
          float: left;
          &:hover{
            transform: translateX(-5px);
          }
          &::after{
            content: '\e625';
          }
        }
        .swiper-button-next{
          float: right;
          &:hover{
            transform: translateX(5px);
          }
          &::after{
            content: '\e624';
          }
        }
      }
    }
    .right{
      @include res(width,80.3%,(sm:75%,xs:100%));
      @include res(float,right,(xs:none));
      .swiper{
        overflow: hidden;
        @include res(margin-bottom,70px, 40 / 70);
        .swiper-slide{
          transition: all .6s;
          @include res(opacity,0,(md:1));
          @include res(transform,translateX(10%),(md:translateX(0%)));
          &:hover{
            .item-img b{
              transform: scale(1.05);
            }
            .item-text .title{
              color: #000;
            }
          }
          .item-img{
            position: relative;
            overflow: hidden;
            @include res(height,350px,(lg:320px,mmd:250px,md:200px,sm:150px));
            @include res(margin-bottom,25px, 15 / 25);
            b{
              display: block;
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              background-position: center;
              background-size: cover;
              transition: all .3s;
            }
          }
          .item-text{
            .date{
              color: $color-main-light;
              @include res(font-size,20px, 14 / 20);
            }
            .title{
              color: $color-desc;
              line-height: 1.5;
              transition: all .3s;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              @include res(font-size,24px, 16 / 24)
            }
          }
        }
      }
      .swiper-page{
        box-sizing: border-box;
        position: relative;
        @include res(padding-left,44px, 30 / 44);
        @include res(padding-right,44px, 30 / 44);
        .swiper-pagination{
          width: 100%;
          height: 1px;
          position: static;
          background: rgba(0,0,0,.2);
          .swiper-pagination-progressbar-fill{
            height: 2px;
            background: #0295ff;
            margin-top: -1px;
          }
        }
        .active-num,.total{
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          color: #000;
          @include res(font-size, 20px, 16 / 20);
        }
        .active-num{
          left: 0;
        }
        .total{
          right: 0;
          opacity: .3;
        }
      }
    }
  }
}

.index-footer{
  footer{
    @include res(height,100vh,(md:auto));
    .footer{
      @include res(padding-top,20vh,(mmd:17vh,md:60px,sm:40px,xs:30px));
      .left-info{
        @include res(transition,all .6s,(md:none));
        @include res(opacity,0,(md:1));
        @include res(transform,translateX(-10%),(md:translateX(0%)));
      }
      .right-nav{
        ul{
          @include res(transition,all .6s,(md:none));
          @include res(opacity,0,(md:1));
          @include res(transform,translateX(10%),(md:translateX(0%)));
        }
      }
    }
    .footer-bottom{
      @include res(position,absolute,(md:static));
    }
  }
  &.active{
    footer{
      .footer{
        .left-info{
          transition-delay: .2s;
          opacity: 1;
          transform: translateX(0%);
        }
        .right-nav{
          ul{
            opacity: 1;
            transform: translateX(0%);
            &:nth-child(1){
              transition-delay: .2s;
            }
            &:nth-child(2){
              transition-delay: .3s;
            }
            &:nth-child(3){
              transition-delay: .4s;
            }
            &:nth-child(4){
              transition-delay: .5s;
            }
          }
        }
      }
    }
  }
}

//圆形进度条
@keyframes circle {
  0% {
    stroke-dashoffset: 151px;
  }
  50% {
    stroke-dashoffset: 75px;
  }
  100% {
    stroke-dashoffset: 0px;
  }
}
@keyframes circle2 {
  0% {
    stroke-dashoffset: 132px;
  }
  50% {
    stroke-dashoffset: 66px;
  }
  100% {
    stroke-dashoffset: 0px;
  }
}
@keyframes circle3 {
  0% {
    stroke-dashoffset: 120px;
  }
  50% {
    stroke-dashoffset: 60px;
  }
  100% {
    stroke-dashoffset: 0px;
  }
}


//旋转
@keyframes rotate {
  0%{
    transform: rotate(0deg);
  }
  50%{
    transform: rotate(180deg);
  }
  100%{
    transform: rotate(360deg);
  }
}