.section2 .cont .section2-title .swiper-tab:after, .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-btn:after, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-btn:after, .section3 .mycontainer .left .swiper-contral:after, .section3 .mycontainer .left .swiper-contral .swiper-btn:after, .section4 .mycontainer:after, .section4 .mycontainer .left:after, .section4 .mycontainer .left .swiper-btn:after {
  content: "";
  display: block;
  clear: both;
}

*::-webkit-scrollbar {
  width: 0;
}

.wow {
  visibility: visible !important;
}

@media (max-width: 1024px) {
  .wow {
    visibility: hidden;
  }
}

.section {
  overflow: hidden;
  height: 100vh;
}

@media (max-width: 1024px) {
  .section {
    overflow: visible;
  }
}

@media (max-width: 1024px) {
  .section {
    height: auto;
  }
}

.index-common-title {
  color: #000;
  position: relative;
  white-space: nowrap;
}

.index-common-title.white {
  color: #fff;
}

.index-common-title::after {
  content: '';
  display: block;
  background: url(../../assets/images/title_bg.jpg) no-repeat;
  position: absolute;
  background-size: contain;
  width: 12px;
  height: 24px;
  top: 25px;
  left: -28px;
}

@media (max-width: 1600px) {
  .index-common-title::after {
    width: 11.2px;
  }
}

@media (max-width: 1366px) {
  .index-common-title::after {
    width: 10.4px;
  }
}

@media (max-width: 1024px) {
  .index-common-title::after {
    width: 9.6px;
  }
}

@media (max-width: 991px) {
  .index-common-title::after {
    width: 8.8px;
  }
}

@media (max-width: 767px) {
  .index-common-title::after {
    width: 8px;
  }
}

@media (max-width: 1600px) {
  .index-common-title::after {
    height: 22px;
  }
}

@media (max-width: 1366px) {
  .index-common-title::after {
    height: 20px;
  }
}

@media (max-width: 1024px) {
  .index-common-title::after {
    height: 18px;
  }
}

@media (max-width: 991px) {
  .index-common-title::after {
    height: 16px;
  }
}

@media (max-width: 767px) {
  .index-common-title::after {
    height: 14px;
  }
}

@media (max-width: 1600px) {
  .index-common-title::after {
    top: 22px;
  }
}

@media (max-width: 1366px) {
  .index-common-title::after {
    top: 19px;
  }
}

@media (max-width: 1024px) {
  .index-common-title::after {
    top: 16px;
  }
}

@media (max-width: 991px) {
  .index-common-title::after {
    top: 13px;
  }
}

@media (max-width: 767px) {
  .index-common-title::after {
    top: 10px;
  }
}

@media (max-width: 1600px) {
  .index-common-title::after {
    left: -25.2px;
  }
}

@media (max-width: 1366px) {
  .index-common-title::after {
    left: -22.4px;
  }
}

@media (max-width: 1024px) {
  .index-common-title::after {
    left: -19.6px;
  }
}

@media (max-width: 991px) {
  .index-common-title::after {
    left: -16.8px;
  }
}

@media (max-width: 767px) {
  .index-common-title::after {
    left: -14px;
  }
}

.index-common-title h1 {
  letter-spacing: 5px;
  font-size: 48px;
}

@media (max-width: 1600px) {
  .index-common-title h1 {
    letter-spacing: 4.4px;
  }
}

@media (max-width: 1366px) {
  .index-common-title h1 {
    letter-spacing: 3.8px;
  }
}

@media (max-width: 1024px) {
  .index-common-title h1 {
    letter-spacing: 3.2px;
  }
}

@media (max-width: 991px) {
  .index-common-title h1 {
    letter-spacing: 2.6px;
  }
}

@media (max-width: 767px) {
  .index-common-title h1 {
    letter-spacing: 2px;
  }
}

@media (max-width: 1600px) {
  .index-common-title h1 {
    font-size: 42.8px;
  }
}

@media (max-width: 1366px) {
  .index-common-title h1 {
    font-size: 37.6px;
  }
}

@media (max-width: 1024px) {
  .index-common-title h1 {
    font-size: 32.4px;
  }
}

@media (max-width: 991px) {
  .index-common-title h1 {
    font-size: 27.2px;
  }
}

@media (max-width: 767px) {
  .index-common-title h1 {
    font-size: 22px;
  }
}

@media (max-width: 991px) {
  .index-common-title h1 {
    font-weight: bold;
  }
}

@media screen and (max-width: 1366px) {
  .index-common-title h1 {
    font-size: 40px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1600px) {
  .index-common-title h1 {
    font-size: 36.8px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1366px) {
  .index-common-title h1 {
    font-size: 33.6px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1024px) {
  .index-common-title h1 {
    font-size: 30.4px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 991px) {
  .index-common-title h1 {
    font-size: 27.2px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 767px) {
  .index-common-title h1 {
    font-size: 24px;
  }
}

.index-common-title h3 {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 21px;
}

@media (max-width: 1600px) {
  .index-common-title h3 {
    letter-spacing: 1.8px;
  }
}

@media (max-width: 1366px) {
  .index-common-title h3 {
    letter-spacing: 1.6px;
  }
}

@media (max-width: 1024px) {
  .index-common-title h3 {
    letter-spacing: 1.4px;
  }
}

@media (max-width: 991px) {
  .index-common-title h3 {
    letter-spacing: 1.2px;
  }
}

@media (max-width: 767px) {
  .index-common-title h3 {
    letter-spacing: 1px;
  }
}

@media (max-width: 1600px) {
  .index-common-title h3 {
    font-size: 20px;
  }
}

@media (max-width: 1366px) {
  .index-common-title h3 {
    font-size: 19px;
  }
}

@media (max-width: 1024px) {
  .index-common-title h3 {
    font-size: 18px;
  }
}

@media (max-width: 991px) {
  .index-common-title h3 {
    font-size: 17px;
  }
}

@media (max-width: 767px) {
  .index-common-title h3 {
    font-size: 16px;
  }
}

.index-banner {
  overflow: hidden;
  position: relative;
  height: 100vh;
}

.index-banner .swiper {
  height: 100%;
  overflow: hidden;
}

.index-banner .swiper .swiper-slide {
  overflow: hidden;
  position: relative;
}

.index-banner .swiper .swiper-slide.ani-slide b {
  transform: scale(1);
}

.index-banner .swiper .swiper-slide .video {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: block;
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-slide .video {
    display: none;
  }
}

.index-banner .swiper .swiper-slide .video video {
  width: 100%;
  height: 100%;
  transform: scale(1.2);
  transform-origin: center;
}

.index-banner .swiper .swiper-slide b {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: center;
  background-size: cover;
  background-position: center;
  transform: scale(1.1);
  transition: all 5s;
  z-index: 0;
}

.index-banner .swiper .swiper-slide b.pc-img {
  display: block;
}

.index-banner .swiper .swiper-slide b.mob-img {
  display: none;
}

.index-banner .swiper .swiper-pagination {
  text-align: left;
  padding-left: 160px;
  padding-right: 160px;
  bottom: 80px;
}

@media (max-width: 1600px) {
  .index-banner .swiper .swiper-pagination {
    padding-left: 132px;
  }
}

@media (max-width: 1366px) {
  .index-banner .swiper .swiper-pagination {
    padding-left: 104px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-pagination {
    padding-left: 76px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-pagination {
    padding-left: 48px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-pagination {
    padding-left: 20px;
  }
}

@media (max-width: 1600px) {
  .index-banner .swiper .swiper-pagination {
    padding-right: 132px;
  }
}

@media (max-width: 1366px) {
  .index-banner .swiper .swiper-pagination {
    padding-right: 104px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-pagination {
    padding-right: 76px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-pagination {
    padding-right: 48px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-pagination {
    padding-right: 20px;
  }
}

@media (max-width: 1600px) {
  .index-banner .swiper .swiper-pagination {
    bottom: 70px;
  }
}

@media (max-width: 1366px) {
  .index-banner .swiper .swiper-pagination {
    bottom: 60px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-pagination {
    bottom: 50px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-pagination {
    bottom: 40px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-pagination {
    bottom: 30px;
  }
}

.index-banner .swiper .swiper-pagination .swiper-pagination-bullet {
  opacity: 1;
  background: transparent;
  position: relative;
  box-sizing: border-box;
  margin-left: 0;
  transition: all .3s;
  border: 1px solid transparent;
  margin-right: 20px;
  width: 50px;
  height: 50px;
}

@media (max-width: 1600px) {
  .index-banner .swiper .swiper-pagination .swiper-pagination-bullet {
    margin-right: 18px;
  }
}

@media (max-width: 1366px) {
  .index-banner .swiper .swiper-pagination .swiper-pagination-bullet {
    margin-right: 16px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-pagination .swiper-pagination-bullet {
    margin-right: 14px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-pagination .swiper-pagination-bullet {
    margin-right: 12px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-pagination .swiper-pagination-bullet {
    margin-right: 10px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-pagination .swiper-pagination-bullet {
    width: 44px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-pagination .swiper-pagination-bullet {
    width: 40px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-pagination .swiper-pagination-bullet {
    height: 44px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-pagination .swiper-pagination-bullet {
    height: 40px;
  }
}

.index-banner .swiper .swiper-pagination .swiper-pagination-bullet:hover::after {
  transform: translateX(-50%) translateY(-50%) scale(1.4);
}

.index-banner .swiper .swiper-pagination .swiper-pagination-bullet::after {
  content: '';
  display: block;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: all .3s;
  transform: translateX(-50%) translateY(-50%);
  width: 10px;
  height: 10px;
}

@media (max-width: 1600px) {
  .index-banner .swiper .swiper-pagination .swiper-pagination-bullet::after {
    width: 9.6px;
  }
}

@media (max-width: 1366px) {
  .index-banner .swiper .swiper-pagination .swiper-pagination-bullet::after {
    width: 9.2px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-pagination .swiper-pagination-bullet::after {
    width: 8.8px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-pagination .swiper-pagination-bullet::after {
    width: 8.4px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-pagination .swiper-pagination-bullet::after {
    width: 8px;
  }
}

@media (max-width: 1600px) {
  .index-banner .swiper .swiper-pagination .swiper-pagination-bullet::after {
    height: 9.6px;
  }
}

@media (max-width: 1366px) {
  .index-banner .swiper .swiper-pagination .swiper-pagination-bullet::after {
    height: 9.2px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-pagination .swiper-pagination-bullet::after {
    height: 8.8px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-pagination .swiper-pagination-bullet::after {
    height: 8.4px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper .swiper-pagination .swiper-pagination-bullet::after {
    height: 8px;
  }
}

.index-banner .swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.index-banner .swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active svg {
  opacity: 1;
}

.index-banner .swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active svg circle {
  animation: 6s circle linear;
  animation-name: circle;
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active svg circle {
    animation-name: circle2;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active svg circle {
    animation-name: circle3;
  }
}

.index-banner .swiper .swiper-pagination .swiper-pagination-bullet svg {
  position: absolute;
  top: -1px;
  left: -1px;
  z-index: 1;
  transform-origin: center;
  transform-box: fill-box;
  transform: rotate(-90deg);
  transition: all .3s;
  opacity: 0;
  width: 50px;
  height: 50px;
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-pagination .swiper-pagination-bullet svg {
    width: 44px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-pagination .swiper-pagination-bullet svg {
    width: 40px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-pagination .swiper-pagination-bullet svg {
    height: 44px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-pagination .swiper-pagination-bullet svg {
    height: 40px;
  }
}

.index-banner .swiper .swiper-pagination .swiper-pagination-bullet svg circle {
  stroke-width: 1;
  fill: none;
  stroke: #fff;
  stroke-dasharray: 151px;
  stroke-dashoffset: 151px;
  cx: 25px;
  cy: 25px;
  r: 24px;
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-pagination .swiper-pagination-bullet svg circle {
    stroke-dasharray: 132px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-pagination .swiper-pagination-bullet svg circle {
    stroke-dasharray: 120px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-pagination .swiper-pagination-bullet svg circle {
    stroke-dashoffset: 132px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-pagination .swiper-pagination-bullet svg circle {
    stroke-dashoffset: 120px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-pagination .swiper-pagination-bullet svg circle {
    cx: 22px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-pagination .swiper-pagination-bullet svg circle {
    cx: 20px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-pagination .swiper-pagination-bullet svg circle {
    cy: 22px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-pagination .swiper-pagination-bullet svg circle {
    cy: 20px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper .swiper-pagination .swiper-pagination-bullet svg circle {
    r: 21px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper .swiper-pagination .swiper-pagination-bullet svg circle {
    r: 19px;
  }
}

.index-banner .banner-t {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 2;
  transform: translateY(-50%);
  padding-left: 160px;
  padding-right: 160px;
}

@media (max-width: 1600px) {
  .index-banner .banner-t {
    padding-left: 132px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-t {
    padding-left: 104px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-t {
    padding-left: 76px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-t {
    padding-left: 48px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-t {
    padding-left: 20px;
  }
}

@media (max-width: 1600px) {
  .index-banner .banner-t {
    padding-right: 132px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-t {
    padding-right: 104px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-t {
    padding-right: 76px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-t {
    padding-right: 48px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-t {
    padding-right: 20px;
  }
}

.index-banner .banner-t.active h1, .index-banner .banner-t.active h2, .index-banner .banner-t.active .link {
  opacity: 1;
  transform: translateY(0%);
}

.index-banner .banner-t h1 {
  color: #fff;
  line-height: 1;
  transition: all .5s;
  opacity: 0;
  transform: translateY(10%);
  letter-spacing: 3px;
  font-size: 88px;
  margin-bottom: 45px;
}

@media (max-width: 1600px) {
  .index-banner .banner-t h1 {
    letter-spacing: 2.6px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-t h1 {
    letter-spacing: 2.2px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-t h1 {
    letter-spacing: 1.8px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-t h1 {
    letter-spacing: 1.4px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-t h1 {
    letter-spacing: 1px;
  }
}

@media (max-width: 1600px) {
  .index-banner .banner-t h1 {
    font-size: 75.6px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-t h1 {
    font-size: 63.2px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-t h1 {
    font-size: 50.8px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-t h1 {
    font-size: 38.4px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-t h1 {
    font-size: 26px;
  }
}

@media (max-width: 1600px) {
  .index-banner .banner-t h1 {
    margin-bottom: 40px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-t h1 {
    margin-bottom: 35px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-t h1 {
    margin-bottom: 30px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-t h1 {
    margin-bottom: 25px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-t h1 {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1366px) {
  .index-banner .banner-t h1 {
    font-size: 60px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1600px) {
  .index-banner .banner-t h1 {
    font-size: 53.2px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1366px) {
  .index-banner .banner-t h1 {
    font-size: 46.4px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1024px) {
  .index-banner .banner-t h1 {
    font-size: 39.6px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 991px) {
  .index-banner .banner-t h1 {
    font-size: 32.8px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 767px) {
  .index-banner .banner-t h1 {
    font-size: 26px;
  }
}

.index-banner .banner-t h2 {
  color: #fff;
  line-height: 1;
  opacity: .4;
  font-weight: lighter;
  transition: all .5s;
  opacity: 0;
  transform: translateY(10%);
  transition-delay: .2s;
  margin-bottom: 80px;
  font-size: 38px;
}

@media (max-width: 1600px) {
  .index-banner .banner-t h2 {
    margin-bottom: 67.6px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-t h2 {
    margin-bottom: 55.2px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-t h2 {
    margin-bottom: 42.8px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-t h2 {
    margin-bottom: 30.4px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-t h2 {
    margin-bottom: 18px;
  }
}

@media (max-width: 1600px) {
  .index-banner .banner-t h2 {
    font-size: 34px;
  }
}

@media (max-width: 1366px) {
  .index-banner .banner-t h2 {
    font-size: 30px;
  }
}

@media (max-width: 1024px) {
  .index-banner .banner-t h2 {
    font-size: 26px;
  }
}

@media (max-width: 991px) {
  .index-banner .banner-t h2 {
    font-size: 22px;
  }
}

@media (max-width: 767px) {
  .index-banner .banner-t h2 {
    font-size: 18px;
  }
}

@media screen and (max-width: 1366px) {
  .index-banner .banner-t h2 {
    font-size: 30px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1600px) {
  .index-banner .banner-t h2 {
    font-size: 27.2px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1366px) {
  .index-banner .banner-t h2 {
    font-size: 24.4px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1024px) {
  .index-banner .banner-t h2 {
    font-size: 21.6px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 991px) {
  .index-banner .banner-t h2 {
    font-size: 18.8px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 767px) {
  .index-banner .banner-t h2 {
    font-size: 16px;
  }
}

.index-banner .banner-t .link {
  transition: all .5s;
  opacity: 0;
  transform: translateY(10%);
  transition-delay: .4s;
}

.index-banner .scroll-icon {
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  white-space: nowrap;
  z-index: 2;
  text-align: center;
  display: block;
}

@media (max-width: 1024px) {
  .index-banner .scroll-icon {
    display: none;
  }
}

.index-banner .scroll-icon .icon {
  position: relative;
  border: 1px solid #fff;
  border-radius: 20px;
  margin-bottom: 25px;
  width: 24px;
  height: 40px;
}

@media (max-width: 1600px) {
  .index-banner .scroll-icon .icon {
    margin-bottom: 23px;
  }
}

@media (max-width: 1366px) {
  .index-banner .scroll-icon .icon {
    margin-bottom: 21px;
  }
}

@media (max-width: 1024px) {
  .index-banner .scroll-icon .icon {
    margin-bottom: 19px;
  }
}

@media (max-width: 991px) {
  .index-banner .scroll-icon .icon {
    margin-bottom: 17px;
  }
}

@media (max-width: 767px) {
  .index-banner .scroll-icon .icon {
    margin-bottom: 15px;
  }
}

.index-banner .scroll-icon .icon::after {
  content: '';
  display: block;
  width: 2px;
  height: 7px;
  background-color: #fff;
  position: absolute;
  top: 10%;
  left: calc(50% - 1px);
  animation: rain 1.3s infinite;
}

.index-banner .scroll-icon .t {
  writing-mode: tb-rl;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: lighter;
  opacity: .4;
  font-weight: lighter;
  letter-spacing: 2px;
}

.section2 {
  position: relative;
  background: url(./images/index_dian_bg1.png) no-repeat 40% top;
  padding-bottom: 0;
}

@media (max-width: 1024px) {
  .section2 {
    padding-bottom: 100px;
  }
}

@media (max-width: 991px) {
  .section2 {
    padding-bottom: 60px;
  }
}

@media (max-width: 767px) {
  .section2 {
    padding-bottom: 40px;
  }
}

.section2::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: url(./images/index_dian_bg1.png) no-repeat 40% top #fff;
}

.section2.active .cont .swiper-cont .pro-swiper .left::after, .section2.active .cont .swiper-cont .trade-swiper .left::after {
  transform: scaleX(0);
}

.section2.active .cont .swiper-cont .pro-swiper .right .swiper, .section2.active .cont .swiper-cont .trade-swiper .right .swiper {
  opacity: 1;
  transform: translateY(0);
  transition-delay: .2s;
}

.section2 .mycontainer {
  position: relative;
  z-index: 2;
}

.section2 .cont {
  position: relative;
  margin-top: 10vh;
}

@media (max-width: 1024px) {
  .section2 .cont {
    margin-top: 100px;
  }
}

@media (max-width: 991px) {
  .section2 .cont {
    margin-top: 60px;
  }
}

@media (max-width: 767px) {
  .section2 .cont {
    margin-top: 40px;
  }
}

.section2 .cont .section2-title {
  top: 0;
  left: 0;
  z-index: 2;
  position: absolute;
  width: 53.12%;
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .section2 .cont .section2-title {
    position: relative;
  }
}

@media (max-width: 991px) {
  .section2 .cont .section2-title {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .section2 .cont .section2-title {
    margin-bottom: 20px;
  }
}

.section2 .cont .section2-title .swiper-tab {
  top: 50%;
  right: 0;
  position: absolute;
  transform: translateY(-50%);
  margin-top: 0;
}

@media (max-width: 767px) {
  .section2 .cont .section2-title .swiper-tab {
    position: static;
  }
}

@media (max-width: 767px) {
  .section2 .cont .section2-title .swiper-tab {
    transform: translateY(0%);
  }
}

@media (max-width: 767px) {
  .section2 .cont .section2-title .swiper-tab {
    margin-top: 10px;
  }
}

.section2 .cont .section2-title .swiper-tab div {
  cursor: pointer;
  position: relative;
  border-radius: 50px;
  overflow: hidden;
  z-index: 1;
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  padding-left: 22px;
  padding-right: 22px;
  padding-top: 6px;
  padding-bottom: 6px;
  font-size: 21px;
  float: left;
  margin-right: 15px;
}

@media (max-width: 1600px) {
  .section2 .cont .section2-title .swiper-tab div {
    padding-left: 20.6px;
  }
}

@media (max-width: 1366px) {
  .section2 .cont .section2-title .swiper-tab div {
    padding-left: 19.2px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .section2-title .swiper-tab div {
    padding-left: 17.8px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .section2-title .swiper-tab div {
    padding-left: 16.4px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .section2-title .swiper-tab div {
    padding-left: 15px;
  }
}

@media (max-width: 1600px) {
  .section2 .cont .section2-title .swiper-tab div {
    padding-right: 20.6px;
  }
}

@media (max-width: 1366px) {
  .section2 .cont .section2-title .swiper-tab div {
    padding-right: 19.2px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .section2-title .swiper-tab div {
    padding-right: 17.8px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .section2-title .swiper-tab div {
    padding-right: 16.4px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .section2-title .swiper-tab div {
    padding-right: 15px;
  }
}

@media (max-width: 1600px) {
  .section2 .cont .section2-title .swiper-tab div {
    padding-top: 5.4px;
  }
}

@media (max-width: 1366px) {
  .section2 .cont .section2-title .swiper-tab div {
    padding-top: 4.8px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .section2-title .swiper-tab div {
    padding-top: 4.2px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .section2-title .swiper-tab div {
    padding-top: 3.6px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .section2-title .swiper-tab div {
    padding-top: 3px;
  }
}

@media (max-width: 1600px) {
  .section2 .cont .section2-title .swiper-tab div {
    padding-bottom: 5.4px;
  }
}

@media (max-width: 1366px) {
  .section2 .cont .section2-title .swiper-tab div {
    padding-bottom: 4.8px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .section2-title .swiper-tab div {
    padding-bottom: 4.2px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .section2-title .swiper-tab div {
    padding-bottom: 3.6px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .section2-title .swiper-tab div {
    padding-bottom: 3px;
  }
}

@media (max-width: 1600px) {
  .section2 .cont .section2-title .swiper-tab div {
    font-size: 20px;
  }
}

@media (max-width: 1366px) {
  .section2 .cont .section2-title .swiper-tab div {
    font-size: 19px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .section2-title .swiper-tab div {
    font-size: 18px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .section2-title .swiper-tab div {
    font-size: 17px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .section2-title .swiper-tab div {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .section2 .cont .section2-title .swiper-tab div {
    margin-right: 14px;
  }
}

@media (max-width: 1366px) {
  .section2 .cont .section2-title .swiper-tab div {
    margin-right: 13px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .section2-title .swiper-tab div {
    margin-right: 12px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .section2-title .swiper-tab div {
    margin-right: 11px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .section2-title .swiper-tab div {
    margin-right: 10px;
  }
}

.section2 .cont .section2-title .swiper-tab div:hover {
  color: #0032d2;
}

.section2 .cont .section2-title .swiper-tab div.active {
  color: #fff;
}

.section2 .cont .section2-title .swiper-tab div.active::after {
  transform: translateY(0%);
}

.section2 .cont .section2-title .swiper-tab div::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(to bottom, #0091ff, #0056ff);
  position: absolute;
  z-index: -1;
  transition: all 0.4s cubic-bezier(0.77, 0, 0.175, 1);
  transform-origin: center;
  transform: translateY(101%);
}

.section2 .cont .swiper-cont .pro-swiper, .section2 .cont .swiper-cont .trade-swiper {
  box-sizing: border-box;
  position: relative;
  padding-left: 53.12%;
}

@media (max-width: 767px) {
  .section2 .cont .swiper-cont .pro-swiper, .section2 .cont .swiper-cont .trade-swiper {
    padding-left: 0;
  }
}

.section2 .cont .swiper-cont .pro-swiper .left, .section2 .cont .swiper-cont .trade-swiper .left {
  position: absolute;
  bottom: 0;
  left: 0;
  overflow: hidden;
  border-radius: 0;
  height: 75.75%;
  width: 53.12%;
}

@media (max-width: 767px) {
  .section2 .cont .swiper-cont .pro-swiper .left, .section2 .cont .swiper-cont .trade-swiper .left {
    border-radius: 20px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .swiper-cont .pro-swiper .left, .section2 .cont .swiper-cont .trade-swiper .left {
    height: 100%;
  }
}

@media (max-width: 767px) {
  .section2 .cont .swiper-cont .pro-swiper .left, .section2 .cont .swiper-cont .trade-swiper .left {
    width: 100%;
  }
}

.section2 .cont .swiper-cont .pro-swiper .left::after, .section2 .cont .swiper-cont .trade-swiper .left::after {
  content: '';
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  transform-origin: right;
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1);
  transition-delay: .2s;
  display: block;
}

@media (max-width: 1024px) {
  .section2 .cont .swiper-cont .pro-swiper .left::after, .section2 .cont .swiper-cont .trade-swiper .left::after {
    display: none;
  }
}

.section2 .cont .swiper-cont .pro-swiper .left .swiper, .section2 .cont .swiper-cont .trade-swiper .left .swiper {
  height: 100%;
  overflow: hidden;
}

.section2 .cont .swiper-cont .pro-swiper .left .swiper .swiper-slide, .section2 .cont .swiper-cont .trade-swiper .left .swiper .swiper-slide {
  position: relative;
  border-radius: 0;
}

@media (max-width: 767px) {
  .section2 .cont .swiper-cont .pro-swiper .left .swiper .swiper-slide, .section2 .cont .swiper-cont .trade-swiper .left .swiper .swiper-slide {
    border-radius: 20px;
  }
}

.section2 .cont .swiper-cont .pro-swiper .left .swiper .swiper-slide b, .section2 .cont .swiper-cont .trade-swiper .left .swiper .swiper-slide b {
  width: 100%;
  height: 100%;
  display: block;
  background-position: center;
  background-size: cover;
}

.section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
  box-sizing: border-box;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  position: relative;
  z-index: 2;
  background-color: #0054ff;
  border-bottom-left-radius: 0;
  padding-top: 70px;
  padding-bottom: 70px;
  padding-left: 80px;
  padding-right: 80px;
  height: 75vh;
}

@media (max-width: 767px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    background-color: rgba(0, 84, 255, 0.5);
  }
}

@media (max-width: 767px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    border-bottom-left-radius: 20px;
  }
}

@media (max-width: 1600px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    padding-top: 60px;
  }
}

@media (max-width: 1366px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    padding-top: 50px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    padding-top: 40px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    padding-top: 30px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    padding-top: 20px;
  }
}

@media (max-width: 1600px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    padding-bottom: 60px;
  }
}

@media (max-width: 1366px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    padding-bottom: 50px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    padding-bottom: 40px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    padding-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    padding-bottom: 20px;
  }
}

@media (max-width: 1600px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    padding-left: 68px;
  }
}

@media (max-width: 1366px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    padding-left: 56px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    padding-left: 44px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    padding-left: 32px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    padding-left: 20px;
  }
}

@media (max-width: 1600px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    padding-right: 68px;
  }
}

@media (max-width: 1366px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    padding-right: 56px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    padding-right: 44px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    padding-right: 32px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    padding-right: 20px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    height: 520px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    height: 460px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    height: 380px;
  }
}

@media (max-height: 800px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    padding-top: 22px;
    padding-bottom: 22px;
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media (max-height: 800px) and (max-width: 1600px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    padding-top: 21.6px;
  }
}

@media (max-height: 800px) and (max-width: 1366px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    padding-top: 21.2px;
  }
}

@media (max-height: 800px) and (max-width: 1024px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    padding-top: 20.8px;
  }
}

@media (max-height: 800px) and (max-width: 991px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    padding-top: 20.4px;
  }
}

@media (max-height: 800px) and (max-width: 767px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    padding-top: 20px;
  }
}

@media (max-height: 800px) and (max-width: 1600px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    padding-bottom: 21.6px;
  }
}

@media (max-height: 800px) and (max-width: 1366px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    padding-bottom: 21.2px;
  }
}

@media (max-height: 800px) and (max-width: 1024px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    padding-bottom: 20.8px;
  }
}

@media (max-height: 800px) and (max-width: 991px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    padding-bottom: 20.4px;
  }
}

@media (max-height: 800px) and (max-width: 767px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    padding-bottom: 20px;
  }
}

@media (max-height: 800px) and (max-width: 1600px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    padding-left: 52px;
  }
}

@media (max-height: 800px) and (max-width: 1366px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    padding-left: 44px;
  }
}

@media (max-height: 800px) and (max-width: 1024px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    padding-left: 36px;
  }
}

@media (max-height: 800px) and (max-width: 991px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    padding-left: 28px;
  }
}

@media (max-height: 800px) and (max-width: 767px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    padding-left: 20px;
  }
}

@media (max-height: 800px) and (max-width: 1600px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    padding-right: 52px;
  }
}

@media (max-height: 800px) and (max-width: 1366px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    padding-right: 44px;
  }
}

@media (max-height: 800px) and (max-width: 1024px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    padding-right: 36px;
  }
}

@media (max-height: 800px) and (max-width: 991px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    padding-right: 28px;
  }
}

@media (max-height: 800px) and (max-width: 767px) {
  .section2 .cont .swiper-cont .pro-swiper .right, .section2 .cont .swiper-cont .trade-swiper .right {
    padding-right: 20px;
  }
}

.section2 .cont .swiper-cont .pro-swiper .right .swiper, .section2 .cont .swiper-cont .trade-swiper .right .swiper {
  height: 100%;
  position: relative;
  overflow: hidden;
  transition: all .6s;
  opacity: 0;
  transform: translateY(10%);
  padding-bottom: 30px;
}

@media (max-width: 1024px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper, .section2 .cont .swiper-cont .trade-swiper .right .swiper {
    opacity: 1;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper, .section2 .cont .swiper-cont .trade-swiper .right .swiper {
    transform: translateY(0%);
  }
}

@media (max-width: 1600px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper, .section2 .cont .swiper-cont .trade-swiper .right .swiper {
    padding-bottom: 28px;
  }
}

@media (max-width: 1366px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper, .section2 .cont .swiper-cont .trade-swiper .right .swiper {
    padding-bottom: 26px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper, .section2 .cont .swiper-cont .trade-swiper .right .swiper {
    padding-bottom: 24px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper, .section2 .cont .swiper-cont .trade-swiper .right .swiper {
    padding-bottom: 22px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper, .section2 .cont .swiper-cont .trade-swiper .right .swiper {
    padding-bottom: 20px;
  }
}

.section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide {
  color: #fff;
}

.section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide.swiper-slide-active > div, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide.swiper-slide-active > div {
  opacity: 1;
  transform: translateY(0);
}

.section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide > div, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide > div {
  opacity: 0;
  transform: translateY(10%);
  transition: all .6s;
}

.section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .num, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .num {
  line-height: 1;
  font-size: 100px;
  margin-bottom: 40px;
}

@media (max-width: 1600px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .num, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .num {
    font-size: 88px;
  }
}

@media (max-width: 1366px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .num, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .num {
    font-size: 76px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .num, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .num {
    font-size: 64px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .num, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .num {
    font-size: 52px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .num, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .num {
    font-size: 40px;
  }
}

@media (max-width: 1600px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .num, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .num {
    margin-bottom: 36px;
  }
}

@media (max-width: 1366px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .num, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .num {
    margin-bottom: 32px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .num, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .num {
    margin-bottom: 28px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .num, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .num {
    margin-bottom: 24px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .num, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .num {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1366px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .num, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .num {
    font-size: 70px;
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1600px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .num, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .num {
    font-size: 62px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1366px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .num, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .num {
    font-size: 54px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1024px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .num, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .num {
    font-size: 46px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 991px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .num, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .num {
    font-size: 38px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 767px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .num, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .num {
    font-size: 30px;
  }
}

@media (max-height: 800px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .num, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .num {
    margin-bottom: 20px;
    font-size: 70px;
  }
}

@media (max-height: 800px) and (max-width: 1600px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .num, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .num {
    font-size: 62px;
  }
}

@media (max-height: 800px) and (max-width: 1366px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .num, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .num {
    font-size: 54px;
  }
}

@media (max-height: 800px) and (max-width: 1024px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .num, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .num {
    font-size: 46px;
  }
}

@media (max-height: 800px) and (max-width: 991px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .num, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .num {
    font-size: 38px;
  }
}

@media (max-height: 800px) and (max-width: 767px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .num, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .num {
    font-size: 30px;
  }
}

.section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .title, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .title {
  font-size: 36px;
  margin-bottom: 25px;
}

@media (max-width: 1600px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .title, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .title {
    font-size: 32.4px;
  }
}

@media (max-width: 1366px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .title, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .title {
    font-size: 28.8px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .title, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .title {
    font-size: 25.2px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .title, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .title {
    font-size: 21.6px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .title, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .title {
    font-size: 18px;
  }
}

@media (max-width: 1600px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .title, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .title {
    margin-bottom: 22px;
  }
}

@media (max-width: 1366px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .title, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .title {
    margin-bottom: 19px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .title, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .title {
    margin-bottom: 16px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .title, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .title {
    margin-bottom: 13px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .title, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .title {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 1366px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .title, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .title {
    font-size: 30px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1600px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .title, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .title {
    font-size: 27.6px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1366px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .title, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .title {
    font-size: 25.2px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1024px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .title, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .title {
    font-size: 22.8px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 991px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .title, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .title {
    font-size: 20.4px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 767px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .title, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .title {
    font-size: 18px;
  }
}

@media (max-height: 800px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .title, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .title {
    margin-bottom: 10px;
    font-size: 30px;
  }
}

@media (max-height: 800px) and (max-width: 1600px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .title, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .title {
    font-size: 27.6px;
  }
}

@media (max-height: 800px) and (max-width: 1366px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .title, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .title {
    font-size: 25.2px;
  }
}

@media (max-height: 800px) and (max-width: 1024px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .title, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .title {
    font-size: 22.8px;
  }
}

@media (max-height: 800px) and (max-width: 991px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .title, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .title {
    font-size: 20.4px;
  }
}

@media (max-height: 800px) and (max-width: 767px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .title, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .title {
    font-size: 18px;
  }
}

.section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .desc, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .desc {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  font-size: 18px;
  margin-bottom: 50px;
}

@media (max-width: 1600px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .desc, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .desc {
    font-size: 17.2px;
  }
}

@media (max-width: 1366px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .desc, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .desc {
    font-size: 16.4px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .desc, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .desc {
    font-size: 15.6px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .desc, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .desc {
    font-size: 14.8px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .desc, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .desc {
    font-size: 14px;
  }
}

@media (max-width: 1600px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .desc, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .desc {
    margin-bottom: 44px;
  }
}

@media (max-width: 1366px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .desc, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .desc {
    margin-bottom: 38px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .desc, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .desc {
    margin-bottom: 32px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .desc, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .desc {
    margin-bottom: 26px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .desc, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .desc {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1366px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .desc, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .desc {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1600px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .desc, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .desc {
    margin-bottom: 28px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1366px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .desc, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .desc {
    margin-bottom: 26px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1024px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .desc, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .desc {
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 991px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .desc, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .desc {
    margin-bottom: 22px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 767px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .desc, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .desc {
    margin-bottom: 20px;
  }
}

@media (max-height: 800px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .desc, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .desc {
    margin-bottom: 30px;
  }
}

@media (max-height: 800px) and (max-width: 1600px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .desc, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .desc {
    margin-bottom: 28px;
  }
}

@media (max-height: 800px) and (max-width: 1366px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .desc, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .desc {
    margin-bottom: 26px;
  }
}

@media (max-height: 800px) and (max-width: 1024px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .desc, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .desc {
    margin-bottom: 24px;
  }
}

@media (max-height: 800px) and (max-width: 991px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .desc, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .desc {
    margin-bottom: 22px;
  }
}

@media (max-height: 800px) and (max-width: 767px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-slide .desc, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-slide .desc {
    margin-bottom: 20px;
  }
}

.section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 2;
}

.section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-btn, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-btn {
  width: 20%;
  float: right;
}

.section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-btn .swiper-button-prev, .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-btn .swiper-button-next, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-btn .swiper-button-prev, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-btn .swiper-button-next {
  position: static;
  background-image: none;
  width: auto;
  height: auto;
  margin-top: 0;
  transition: all .3s;
  line-height: 1;
}

.section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-btn .swiper-button-prev::after, .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-btn .swiper-button-next::after, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-btn .swiper-button-prev::after, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-btn .swiper-button-next::after {
  font-family: 'iconfont';
  color: #fff;
  font-size: 36px;
}

@media (max-width: 1600px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-btn .swiper-button-prev::after, .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-btn .swiper-button-next::after, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-btn .swiper-button-prev::after, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-btn .swiper-button-next::after {
    font-size: 32.8px;
  }
}

@media (max-width: 1366px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-btn .swiper-button-prev::after, .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-btn .swiper-button-next::after, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-btn .swiper-button-prev::after, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-btn .swiper-button-next::after {
    font-size: 29.6px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-btn .swiper-button-prev::after, .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-btn .swiper-button-next::after, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-btn .swiper-button-prev::after, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-btn .swiper-button-next::after {
    font-size: 26.4px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-btn .swiper-button-prev::after, .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-btn .swiper-button-next::after, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-btn .swiper-button-prev::after, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-btn .swiper-button-next::after {
    font-size: 23.2px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-btn .swiper-button-prev::after, .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-btn .swiper-button-next::after, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-btn .swiper-button-prev::after, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-btn .swiper-button-next::after {
    font-size: 20px;
  }
}

.section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-btn .swiper-button-prev, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-btn .swiper-button-prev {
  float: left;
}

.section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-btn .swiper-button-prev:hover, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-btn .swiper-button-prev:hover {
  transform: translateX(-5px);
}

.section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-btn .swiper-button-prev::after, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-btn .swiper-button-prev::after {
  content: '\e625';
}

.section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-btn .swiper-button-next, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-btn .swiper-button-next {
  float: right;
}

.section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-btn .swiper-button-next:hover, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-btn .swiper-button-next:hover {
  transform: translateX(5px);
}

.section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-btn .swiper-button-next::after, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-btn .swiper-button-next::after {
  content: '\e624';
}

.section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-page, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-page {
  position: absolute;
  box-sizing: border-box;
  left: 0;
  top: 50%;
  float: left;
  width: 65%;
  padding-left: 44px;
  padding-right: 44px;
}

@media (max-width: 1600px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-page, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-page {
    padding-left: 41.2px;
  }
}

@media (max-width: 1366px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-page, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-page {
    padding-left: 38.4px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-page, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-page {
    padding-left: 35.6px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-page, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-page {
    padding-left: 32.8px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-page, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-page {
    padding-left: 30px;
  }
}

@media (max-width: 1600px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-page, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-page {
    padding-right: 41.2px;
  }
}

@media (max-width: 1366px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-page, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-page {
    padding-right: 38.4px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-page, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-page {
    padding-right: 35.6px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-page, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-page {
    padding-right: 32.8px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-page, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-page {
    padding-right: 30px;
  }
}

.section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-page .swiper-pagination, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-page .swiper-pagination {
  width: 100%;
  height: 1px;
  position: static;
  background: rgba(255, 255, 255, 0.2);
}

.section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-page .swiper-pagination .swiper-pagination-progressbar-fill, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-page .swiper-pagination .swiper-pagination-progressbar-fill {
  height: 2px;
  background: #4773ff;
  margin-top: -1px;
}

.section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-page .active-num, .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-page .total, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-page .active-num, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-page .total {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  font-size: 20px;
}

@media (max-width: 1600px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-page .active-num, .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-page .total, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-page .active-num, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-page .total {
    font-size: 19.2px;
  }
}

@media (max-width: 1366px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-page .active-num, .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-page .total, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-page .active-num, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-page .total {
    font-size: 18.4px;
  }
}

@media (max-width: 1024px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-page .active-num, .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-page .total, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-page .active-num, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-page .total {
    font-size: 17.6px;
  }
}

@media (max-width: 991px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-page .active-num, .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-page .total, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-page .active-num, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-page .total {
    font-size: 16.8px;
  }
}

@media (max-width: 767px) {
  .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-page .active-num, .section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-page .total, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-page .active-num, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-page .total {
    font-size: 16px;
  }
}

.section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-page .active-num, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-page .active-num {
  left: 0;
}

.section2 .cont .swiper-cont .pro-swiper .right .swiper .swiper-contral .swiper-page .total, .section2 .cont .swiper-cont .trade-swiper .right .swiper .swiper-contral .swiper-page .total {
  right: 0;
}

.section3 {
  background-color: #0b0c45;
  position: relative;
  overflow: hidden;
  padding-top: 0;
  padding-bottom: 0;
}

@media (max-width: 1024px) {
  .section3 {
    padding-top: 70px;
  }
}

@media (max-width: 991px) {
  .section3 {
    padding-top: 30px;
  }
}

@media (max-width: 1024px) {
  .section3 {
    padding-bottom: 100px;
  }
}

@media (max-width: 991px) {
  .section3 {
    padding-bottom: 40px;
  }
}

.section3.active .mycontainer .left .index-common-title {
  opacity: 1;
  transform: translateY(0);
  transition-delay: .3s;
}

.section3.active .mycontainer .left .text h2, .section3.active .mycontainer .left .text .desc, .section3.active .mycontainer .left .text .link {
  opacity: 1;
  transform: translateY(0);
}

.section3.active .mycontainer .left .text h2 {
  transition-delay: .4s;
}

.section3.active .mycontainer .left .text .desc {
  transition-delay: .5s;
}

.section3.active .mycontainer .left .text .link {
  transition-delay: .6s;
}

.section3 .bg-img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  animation: rotate 100s linear infinite;
  display: block;
  background-size: contain;
}

@media (max-width: 767px) {
  .section3 .bg-img {
    background-size: 200% auto;
  }
}

.section3 .mycontainer {
  position: relative;
  z-index: 1;
}

.section3 .mycontainer .left {
  width: 42.5%;
  margin-top: 50px;
}

@media (max-width: 767px) {
  .section3 .mycontainer .left {
    width: 100%;
  }
}

@media (max-width: 1600px) {
  .section3 .mycontainer .left {
    margin-top: 42px;
  }
}

@media (max-width: 1366px) {
  .section3 .mycontainer .left {
    margin-top: 34px;
  }
}

@media (max-width: 1024px) {
  .section3 .mycontainer .left {
    margin-top: 26px;
  }
}

@media (max-width: 991px) {
  .section3 .mycontainer .left {
    margin-top: 18px;
  }
}

@media (max-width: 767px) {
  .section3 .mycontainer .left {
    margin-top: 10px;
  }
}

.section3 .mycontainer .left .index-common-title {
  transition: all .5s;
  opacity: 0;
  transform: translateY(20%);
}

@media (max-width: 1024px) {
  .section3 .mycontainer .left .index-common-title {
    opacity: 1;
  }
}

@media (max-width: 1024px) {
  .section3 .mycontainer .left .index-common-title {
    transform: translateY(0%);
  }
}

.section3 .mycontainer .left .text {
  color: #fff;
  max-width: 380px;
  margin-top: 70px;
  margin-bottom: 80px;
}

@media (max-width: 1600px) {
  .section3 .mycontainer .left .text {
    margin-top: 60px;
  }
}

@media (max-width: 1366px) {
  .section3 .mycontainer .left .text {
    margin-top: 50px;
  }
}

@media (max-width: 1024px) {
  .section3 .mycontainer .left .text {
    margin-top: 40px;
  }
}

@media (max-width: 991px) {
  .section3 .mycontainer .left .text {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .section3 .mycontainer .left .text {
    margin-top: 20px;
  }
}

@media (max-width: 1600px) {
  .section3 .mycontainer .left .text {
    margin-bottom: 68px;
  }
}

@media (max-width: 1366px) {
  .section3 .mycontainer .left .text {
    margin-bottom: 56px;
  }
}

@media (max-width: 1024px) {
  .section3 .mycontainer .left .text {
    margin-bottom: 44px;
  }
}

@media (max-width: 991px) {
  .section3 .mycontainer .left .text {
    margin-bottom: 32px;
  }
}

@media (max-width: 767px) {
  .section3 .mycontainer .left .text {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1366px) {
  .section3 .mycontainer .left .text {
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1600px) {
  .section3 .mycontainer .left .text {
    margin-top: 44px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1366px) {
  .section3 .mycontainer .left .text {
    margin-top: 38px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1024px) {
  .section3 .mycontainer .left .text {
    margin-top: 32px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 991px) {
  .section3 .mycontainer .left .text {
    margin-top: 26px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 767px) {
  .section3 .mycontainer .left .text {
    margin-top: 20px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1600px) {
  .section3 .mycontainer .left .text {
    margin-bottom: 44px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1366px) {
  .section3 .mycontainer .left .text {
    margin-bottom: 38px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1024px) {
  .section3 .mycontainer .left .text {
    margin-bottom: 32px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 991px) {
  .section3 .mycontainer .left .text {
    margin-bottom: 26px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 767px) {
  .section3 .mycontainer .left .text {
    margin-bottom: 20px;
  }
}

.section3 .mycontainer .left .text h2, .section3 .mycontainer .left .text .link {
  transition: all .5s;
  opacity: 0;
  transform: translateY(20%);
}

@media (max-width: 1024px) {
  .section3 .mycontainer .left .text h2, .section3 .mycontainer .left .text .link {
    opacity: 1;
  }
}

@media (max-width: 1024px) {
  .section3 .mycontainer .left .text h2, .section3 .mycontainer .left .text .link {
    transform: translateY(0%);
  }
}

.section3 .mycontainer .left .text h2 {
  font-size: 36px;
  margin-bottom: 25px;
}

@media (max-width: 1600px) {
  .section3 .mycontainer .left .text h2 {
    font-size: 32.4px;
  }
}

@media (max-width: 1366px) {
  .section3 .mycontainer .left .text h2 {
    font-size: 28.8px;
  }
}

@media (max-width: 1024px) {
  .section3 .mycontainer .left .text h2 {
    font-size: 25.2px;
  }
}

@media (max-width: 991px) {
  .section3 .mycontainer .left .text h2 {
    font-size: 21.6px;
  }
}

@media (max-width: 767px) {
  .section3 .mycontainer .left .text h2 {
    font-size: 18px;
  }
}

@media (max-width: 1600px) {
  .section3 .mycontainer .left .text h2 {
    margin-bottom: 22px;
  }
}

@media (max-width: 1366px) {
  .section3 .mycontainer .left .text h2 {
    margin-bottom: 19px;
  }
}

@media (max-width: 1024px) {
  .section3 .mycontainer .left .text h2 {
    margin-bottom: 16px;
  }
}

@media (max-width: 991px) {
  .section3 .mycontainer .left .text h2 {
    margin-bottom: 13px;
  }
}

@media (max-width: 767px) {
  .section3 .mycontainer .left .text h2 {
    margin-bottom: 10px;
  }
}

.section3 .mycontainer .left .text .desc {
  transition: all .5s;
  opacity: 0;
  transform: translateY(10%);
  margin-bottom: 40px;
}

@media (max-width: 1024px) {
  .section3 .mycontainer .left .text .desc {
    opacity: 0.7;
  }
}

@media (max-width: 1024px) {
  .section3 .mycontainer .left .text .desc {
    transform: translateY(0%);
  }
}

@media (max-width: 1600px) {
  .section3 .mycontainer .left .text .desc {
    margin-bottom: 34px;
  }
}

@media (max-width: 1366px) {
  .section3 .mycontainer .left .text .desc {
    margin-bottom: 28px;
  }
}

@media (max-width: 1024px) {
  .section3 .mycontainer .left .text .desc {
    margin-bottom: 22px;
  }
}

@media (max-width: 991px) {
  .section3 .mycontainer .left .text .desc {
    margin-bottom: 16px;
  }
}

@media (max-width: 767px) {
  .section3 .mycontainer .left .text .desc {
    margin-bottom: 10px;
  }
}

.section3 .mycontainer .left .swiper-contral {
  position: relative;
}

.section3 .mycontainer .left .swiper-contral .swiper-btn {
  width: 20%;
  float: right;
}

.section3 .mycontainer .left .swiper-contral .swiper-btn .swiper-button-prev, .section3 .mycontainer .left .swiper-contral .swiper-btn .swiper-button-next {
  position: static;
  background-image: none;
  width: auto;
  height: auto;
  margin-top: 0;
  transition: all .3s;
  line-height: 1;
}

.section3 .mycontainer .left .swiper-contral .swiper-btn .swiper-button-prev::after, .section3 .mycontainer .left .swiper-contral .swiper-btn .swiper-button-next::after {
  font-family: 'iconfont';
  color: #fff;
  font-size: 36px;
}

@media (max-width: 1600px) {
  .section3 .mycontainer .left .swiper-contral .swiper-btn .swiper-button-prev::after, .section3 .mycontainer .left .swiper-contral .swiper-btn .swiper-button-next::after {
    font-size: 32.8px;
  }
}

@media (max-width: 1366px) {
  .section3 .mycontainer .left .swiper-contral .swiper-btn .swiper-button-prev::after, .section3 .mycontainer .left .swiper-contral .swiper-btn .swiper-button-next::after {
    font-size: 29.6px;
  }
}

@media (max-width: 1024px) {
  .section3 .mycontainer .left .swiper-contral .swiper-btn .swiper-button-prev::after, .section3 .mycontainer .left .swiper-contral .swiper-btn .swiper-button-next::after {
    font-size: 26.4px;
  }
}

@media (max-width: 991px) {
  .section3 .mycontainer .left .swiper-contral .swiper-btn .swiper-button-prev::after, .section3 .mycontainer .left .swiper-contral .swiper-btn .swiper-button-next::after {
    font-size: 23.2px;
  }
}

@media (max-width: 767px) {
  .section3 .mycontainer .left .swiper-contral .swiper-btn .swiper-button-prev::after, .section3 .mycontainer .left .swiper-contral .swiper-btn .swiper-button-next::after {
    font-size: 20px;
  }
}

.section3 .mycontainer .left .swiper-contral .swiper-btn .swiper-button-prev {
  float: left;
}

.section3 .mycontainer .left .swiper-contral .swiper-btn .swiper-button-prev:hover {
  transform: translateX(-5px);
}

.section3 .mycontainer .left .swiper-contral .swiper-btn .swiper-button-prev::after {
  content: '\e625';
}

.section3 .mycontainer .left .swiper-contral .swiper-btn .swiper-button-next {
  float: right;
}

.section3 .mycontainer .left .swiper-contral .swiper-btn .swiper-button-next:hover {
  transform: translateX(5px);
}

.section3 .mycontainer .left .swiper-contral .swiper-btn .swiper-button-next::after {
  content: '\e624';
}

.section3 .mycontainer .left .swiper-contral .swiper-page {
  position: absolute;
  box-sizing: border-box;
  left: 0;
  top: 50%;
  float: left;
  width: 65%;
  padding-left: 44px;
  padding-right: 44px;
}

@media (max-width: 1600px) {
  .section3 .mycontainer .left .swiper-contral .swiper-page {
    padding-left: 41.2px;
  }
}

@media (max-width: 1366px) {
  .section3 .mycontainer .left .swiper-contral .swiper-page {
    padding-left: 38.4px;
  }
}

@media (max-width: 1024px) {
  .section3 .mycontainer .left .swiper-contral .swiper-page {
    padding-left: 35.6px;
  }
}

@media (max-width: 991px) {
  .section3 .mycontainer .left .swiper-contral .swiper-page {
    padding-left: 32.8px;
  }
}

@media (max-width: 767px) {
  .section3 .mycontainer .left .swiper-contral .swiper-page {
    padding-left: 30px;
  }
}

@media (max-width: 1600px) {
  .section3 .mycontainer .left .swiper-contral .swiper-page {
    padding-right: 41.2px;
  }
}

@media (max-width: 1366px) {
  .section3 .mycontainer .left .swiper-contral .swiper-page {
    padding-right: 38.4px;
  }
}

@media (max-width: 1024px) {
  .section3 .mycontainer .left .swiper-contral .swiper-page {
    padding-right: 35.6px;
  }
}

@media (max-width: 991px) {
  .section3 .mycontainer .left .swiper-contral .swiper-page {
    padding-right: 32.8px;
  }
}

@media (max-width: 767px) {
  .section3 .mycontainer .left .swiper-contral .swiper-page {
    padding-right: 30px;
  }
}

.section3 .mycontainer .left .swiper-contral .swiper-page .swiper-pagination {
  width: 100%;
  height: 1px;
  position: static;
  background: rgba(255, 255, 255, 0.2);
}

.section3 .mycontainer .left .swiper-contral .swiper-page .swiper-pagination .swiper-pagination-progressbar-fill {
  height: 2px;
  background: #0295ff;
  margin-top: -1px;
}

.section3 .mycontainer .left .swiper-contral .swiper-page .active-num, .section3 .mycontainer .left .swiper-contral .swiper-page .total {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
  font-size: 20px;
}

@media (max-width: 1600px) {
  .section3 .mycontainer .left .swiper-contral .swiper-page .active-num, .section3 .mycontainer .left .swiper-contral .swiper-page .total {
    font-size: 19.2px;
  }
}

@media (max-width: 1366px) {
  .section3 .mycontainer .left .swiper-contral .swiper-page .active-num, .section3 .mycontainer .left .swiper-contral .swiper-page .total {
    font-size: 18.4px;
  }
}

@media (max-width: 1024px) {
  .section3 .mycontainer .left .swiper-contral .swiper-page .active-num, .section3 .mycontainer .left .swiper-contral .swiper-page .total {
    font-size: 17.6px;
  }
}

@media (max-width: 991px) {
  .section3 .mycontainer .left .swiper-contral .swiper-page .active-num, .section3 .mycontainer .left .swiper-contral .swiper-page .total {
    font-size: 16.8px;
  }
}

@media (max-width: 767px) {
  .section3 .mycontainer .left .swiper-contral .swiper-page .active-num, .section3 .mycontainer .left .swiper-contral .swiper-page .total {
    font-size: 16px;
  }
}

.section3 .mycontainer .left .swiper-contral .swiper-page .active-num {
  left: 0;
}

.section3 .mycontainer .left .swiper-contral .swiper-page .total {
  right: 0;
  opacity: .3;
}

.section3 .right {
  z-index: 1;
  overflow: hidden;
  margin-top: 0;
  position: absolute;
  width: 44%;
  left: 56%;
  top: 12vh;
  height: 80vh;
}

@media (max-width: 767px) {
  .section3 .right {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .section3 .right {
    position: static;
  }
}

@media (max-width: 1024px) {
  .section3 .right {
    width: 47%;
  }
}

@media (max-width: 991px) {
  .section3 .right {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .section3 .right {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .section3 .right {
    left: 53%;
  }
}

@media (max-width: 991px) {
  .section3 .right {
    left: 50%;
  }
}

@media (max-width: 1024px) {
  .section3 .right {
    top: 0%;
  }
}

@media (max-width: 1024px) {
  .section3 .right {
    height: 100%;
  }
}

@media (max-width: 767px) {
  .section3 .right {
    height: auto;
  }
}

.section3 .right .swiper {
  height: 100%;
}

.section3 .right .swiper .swiper-slide.prev .item {
  opacity: .8;
  transform: scale(0.85) translateX(40%);
}

.section3 .right .swiper .swiper-slide.active .item {
  opacity: 1;
  transform: scale(1) translateX(60%);
}

.section3 .right .swiper .swiper-slide .item {
  background: url(./images/index_icon_bg2.png) no-repeat center;
  background-size: 100% 100%;
  position: relative;
  box-sizing: border-box;
  transform-origin: left center;
  transition: all .5s;
  opacity: 0.4;
  transform: scale(0.7);
  padding-top: 25px;
  padding-left: 70px;
  width: 381px;
  height: 137px;
  margin-left: 44px;
}

@media (max-width: 767px) {
  .section3 .right .swiper .swiper-slide .item {
    opacity: 1;
  }
}

@media (max-width: 767px) {
  .section3 .right .swiper .swiper-slide .item {
    transform: scale(1);
  }
}

@media (max-width: 1600px) {
  .section3 .right .swiper .swiper-slide .item {
    padding-top: 23px;
  }
}

@media (max-width: 1366px) {
  .section3 .right .swiper .swiper-slide .item {
    padding-top: 21px;
  }
}

@media (max-width: 1024px) {
  .section3 .right .swiper .swiper-slide .item {
    padding-top: 19px;
  }
}

@media (max-width: 991px) {
  .section3 .right .swiper .swiper-slide .item {
    padding-top: 17px;
  }
}

@media (max-width: 767px) {
  .section3 .right .swiper .swiper-slide .item {
    padding-top: 15px;
  }
}

@media (max-width: 1600px) {
  .section3 .right .swiper .swiper-slide .item {
    padding-left: 64px;
  }
}

@media (max-width: 1366px) {
  .section3 .right .swiper .swiper-slide .item {
    padding-left: 58px;
  }
}

@media (max-width: 1024px) {
  .section3 .right .swiper .swiper-slide .item {
    padding-left: 52px;
  }
}

@media (max-width: 991px) {
  .section3 .right .swiper .swiper-slide .item {
    padding-left: 46px;
  }
}

@media (max-width: 767px) {
  .section3 .right .swiper .swiper-slide .item {
    padding-left: 40px;
  }
}

@media (max-width: 1600px) {
  .section3 .right .swiper .swiper-slide .item {
    width: 348.8px;
  }
}

@media (max-width: 1366px) {
  .section3 .right .swiper .swiper-slide .item {
    width: 316.6px;
  }
}

@media (max-width: 1024px) {
  .section3 .right .swiper .swiper-slide .item {
    width: 284.4px;
  }
}

@media (max-width: 991px) {
  .section3 .right .swiper .swiper-slide .item {
    width: 252.2px;
  }
}

@media (max-width: 767px) {
  .section3 .right .swiper .swiper-slide .item {
    width: 220px;
  }
}

@media (max-width: 1600px) {
  .section3 .right .swiper .swiper-slide .item {
    height: 125.6px;
  }
}

@media (max-width: 1366px) {
  .section3 .right .swiper .swiper-slide .item {
    height: 114.2px;
  }
}

@media (max-width: 1024px) {
  .section3 .right .swiper .swiper-slide .item {
    height: 102.8px;
  }
}

@media (max-width: 991px) {
  .section3 .right .swiper .swiper-slide .item {
    height: 91.4px;
  }
}

@media (max-width: 767px) {
  .section3 .right .swiper .swiper-slide .item {
    height: 80px;
  }
}

@media (max-width: 1600px) {
  .section3 .right .swiper .swiper-slide .item {
    margin-left: 43.2px;
  }
}

@media (max-width: 1366px) {
  .section3 .right .swiper .swiper-slide .item {
    margin-left: 42.4px;
  }
}

@media (max-width: 1024px) {
  .section3 .right .swiper .swiper-slide .item {
    margin-left: 41.6px;
  }
}

@media (max-width: 991px) {
  .section3 .right .swiper .swiper-slide .item {
    margin-left: 40.8px;
  }
}

@media (max-width: 767px) {
  .section3 .right .swiper .swiper-slide .item {
    margin-left: 40px;
  }
}

@media screen and (max-width: 1366px) {
  .section3 .right .swiper .swiper-slide .item {
    padding-top: 15px;
    padding-left: 70px;
    width: 320px;
    height: 110px;
    margin-left: 30px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1600px) {
  .section3 .right .swiper .swiper-slide .item {
    padding-left: 64px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1366px) {
  .section3 .right .swiper .swiper-slide .item {
    padding-left: 58px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1024px) {
  .section3 .right .swiper .swiper-slide .item {
    padding-left: 52px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 991px) {
  .section3 .right .swiper .swiper-slide .item {
    padding-left: 46px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 767px) {
  .section3 .right .swiper .swiper-slide .item {
    padding-left: 40px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1600px) {
  .section3 .right .swiper .swiper-slide .item {
    width: 300px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1366px) {
  .section3 .right .swiper .swiper-slide .item {
    width: 280px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1024px) {
  .section3 .right .swiper .swiper-slide .item {
    width: 260px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 991px) {
  .section3 .right .swiper .swiper-slide .item {
    width: 240px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 767px) {
  .section3 .right .swiper .swiper-slide .item {
    width: 220px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1600px) {
  .section3 .right .swiper .swiper-slide .item {
    height: 104px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1366px) {
  .section3 .right .swiper .swiper-slide .item {
    height: 98px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1024px) {
  .section3 .right .swiper .swiper-slide .item {
    height: 92px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 991px) {
  .section3 .right .swiper .swiper-slide .item {
    height: 86px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 767px) {
  .section3 .right .swiper .swiper-slide .item {
    height: 80px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1600px) {
  .section3 .right .swiper .swiper-slide .item {
    margin-left: 32px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1366px) {
  .section3 .right .swiper .swiper-slide .item {
    margin-left: 34px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1024px) {
  .section3 .right .swiper .swiper-slide .item {
    margin-left: 36px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 991px) {
  .section3 .right .swiper .swiper-slide .item {
    margin-left: 38px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 767px) {
  .section3 .right .swiper .swiper-slide .item {
    margin-left: 40px;
  }
}

.section3 .right .swiper .swiper-slide .item .item-icon {
  background: url(./images/index_icon_bg.png) no-repeat center;
  background-size: contain;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -44px;
  width: 100px;
  height: 100px;
}

@media (max-width: 1600px) {
  .section3 .right .swiper .swiper-slide .item .item-icon {
    left: -39.2px;
  }
}

@media (max-width: 1366px) {
  .section3 .right .swiper .swiper-slide .item .item-icon {
    left: -34.4px;
  }
}

@media (max-width: 1024px) {
  .section3 .right .swiper .swiper-slide .item .item-icon {
    left: -29.6px;
  }
}

@media (max-width: 991px) {
  .section3 .right .swiper .swiper-slide .item .item-icon {
    left: -24.8px;
  }
}

@media (max-width: 767px) {
  .section3 .right .swiper .swiper-slide .item .item-icon {
    left: -20px;
  }
}

@media (max-width: 1600px) {
  .section3 .right .swiper .swiper-slide .item .item-icon {
    width: 90px;
  }
}

@media (max-width: 1366px) {
  .section3 .right .swiper .swiper-slide .item .item-icon {
    width: 80px;
  }
}

@media (max-width: 1024px) {
  .section3 .right .swiper .swiper-slide .item .item-icon {
    width: 70px;
  }
}

@media (max-width: 991px) {
  .section3 .right .swiper .swiper-slide .item .item-icon {
    width: 60px;
  }
}

@media (max-width: 767px) {
  .section3 .right .swiper .swiper-slide .item .item-icon {
    width: 50px;
  }
}

@media (max-width: 1600px) {
  .section3 .right .swiper .swiper-slide .item .item-icon {
    height: 90px;
  }
}

@media (max-width: 1366px) {
  .section3 .right .swiper .swiper-slide .item .item-icon {
    height: 80px;
  }
}

@media (max-width: 1024px) {
  .section3 .right .swiper .swiper-slide .item .item-icon {
    height: 70px;
  }
}

@media (max-width: 991px) {
  .section3 .right .swiper .swiper-slide .item .item-icon {
    height: 60px;
  }
}

@media (max-width: 767px) {
  .section3 .right .swiper .swiper-slide .item .item-icon {
    height: 50px;
  }
}

@media screen and (max-width: 1366px) {
  .section3 .right .swiper .swiper-slide .item .item-icon {
    left: -35px;
    width: 80px;
    height: 80px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1600px) {
  .section3 .right .swiper .swiper-slide .item .item-icon {
    left: -32px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1366px) {
  .section3 .right .swiper .swiper-slide .item .item-icon {
    left: -29px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1024px) {
  .section3 .right .swiper .swiper-slide .item .item-icon {
    left: -26px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 991px) {
  .section3 .right .swiper .swiper-slide .item .item-icon {
    left: -23px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 767px) {
  .section3 .right .swiper .swiper-slide .item .item-icon {
    left: -20px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1600px) {
  .section3 .right .swiper .swiper-slide .item .item-icon {
    width: 74px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1366px) {
  .section3 .right .swiper .swiper-slide .item .item-icon {
    width: 68px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1024px) {
  .section3 .right .swiper .swiper-slide .item .item-icon {
    width: 62px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 991px) {
  .section3 .right .swiper .swiper-slide .item .item-icon {
    width: 56px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 767px) {
  .section3 .right .swiper .swiper-slide .item .item-icon {
    width: 50px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1600px) {
  .section3 .right .swiper .swiper-slide .item .item-icon {
    height: 74px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1366px) {
  .section3 .right .swiper .swiper-slide .item .item-icon {
    height: 68px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 1024px) {
  .section3 .right .swiper .swiper-slide .item .item-icon {
    height: 62px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 991px) {
  .section3 .right .swiper .swiper-slide .item .item-icon {
    height: 56px;
  }
}

@media screen and (max-width: 1366px) and (max-width: 767px) {
  .section3 .right .swiper .swiper-slide .item .item-icon {
    height: 50px;
  }
}

.section3 .right .swiper .swiper-slide .item .item-icon img {
  display: block;
  width: 100%;
  height: auto;
}

.section3 .right .swiper .swiper-slide .item .item-t {
  white-space: nowrap;
  letter-spacing: 3px;
}

@media (max-width: 1600px) {
  .section3 .right .swiper .swiper-slide .item .item-t {
    letter-spacing: 2.6px;
  }
}

@media (max-width: 1366px) {
  .section3 .right .swiper .swiper-slide .item .item-t {
    letter-spacing: 2.2px;
  }
}

@media (max-width: 1024px) {
  .section3 .right .swiper .swiper-slide .item .item-t {
    letter-spacing: 1.8px;
  }
}

@media (max-width: 991px) {
  .section3 .right .swiper .swiper-slide .item .item-t {
    letter-spacing: 1.4px;
  }
}

@media (max-width: 767px) {
  .section3 .right .swiper .swiper-slide .item .item-t {
    letter-spacing: 1px;
  }
}

.section3 .right .swiper .swiper-slide .item .item-t .en {
  font-weight: lighter;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
  font-size: 22px;
}

@media (max-width: 1600px) {
  .section3 .right .swiper .swiper-slide .item .item-t .en {
    font-size: 20.4px;
  }
}

@media (max-width: 1366px) {
  .section3 .right .swiper .swiper-slide .item .item-t .en {
    font-size: 18.8px;
  }
}

@media (max-width: 1024px) {
  .section3 .right .swiper .swiper-slide .item .item-t .en {
    font-size: 17.2px;
  }
}

@media (max-width: 991px) {
  .section3 .right .swiper .swiper-slide .item .item-t .en {
    font-size: 15.6px;
  }
}

@media (max-width: 767px) {
  .section3 .right .swiper .swiper-slide .item .item-t .en {
    font-size: 14px;
  }
}

.section3 .right .swiper .swiper-slide .item .item-t .cn {
  color: white;
  font-size: 26px;
}

@media (max-width: 1600px) {
  .section3 .right .swiper .swiper-slide .item .item-t .cn {
    font-size: 24px;
  }
}

@media (max-width: 1366px) {
  .section3 .right .swiper .swiper-slide .item .item-t .cn {
    font-size: 22px;
  }
}

@media (max-width: 1024px) {
  .section3 .right .swiper .swiper-slide .item .item-t .cn {
    font-size: 20px;
  }
}

@media (max-width: 991px) {
  .section3 .right .swiper .swiper-slide .item .item-t .cn {
    font-size: 18px;
  }
}

@media (max-width: 767px) {
  .section3 .right .swiper .swiper-slide .item .item-t .cn {
    font-size: 16px;
  }
}

.section4 {
  position: relative;
  padding-bottom: 0;
}

@media (max-width: 1024px) {
  .section4 {
    padding-bottom: 80px;
  }
}

@media (max-width: 991px) {
  .section4 {
    padding-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .section4 {
    padding-bottom: 40px;
  }
}

.section4::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.section4.active .mycontainer .left .index-common-title {
  opacity: 1;
  transform: translateY(0);
  transition-delay: .3s;
}

.section4.active .mycontainer .right .swiper .swiper-slide {
  opacity: 1;
  transform: translateX(0);
}

.section4.active .mycontainer .right .swiper .swiper-slide:nth-child(1) {
  transition-delay: .3s;
}

.section4.active .mycontainer .right .swiper .swiper-slide:nth-child(2) {
  transition-delay: .4s;
}

.section4 .mycontainer {
  position: relative;
  z-index: 2;
  padding-top: 0;
}

@media (max-width: 1366px) {
  .section4 .mycontainer {
    padding-top: 70px;
  }
}

@media (max-width: 991px) {
  .section4 .mycontainer {
    padding-top: 50px;
  }
}

@media (max-width: 767px) {
  .section4 .mycontainer {
    padding-top: 30px;
  }
}

.section4 .mycontainer .left {
  width: 16.8%;
  float: left;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .section4 .mycontainer .left {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .section4 .mycontainer .left {
    float: none;
  }
}

@media (max-width: 767px) {
  .section4 .mycontainer .left {
    margin-bottom: 20px;
  }
}

.section4 .mycontainer .left .index-common-title {
  transition: all .5s;
  opacity: 0;
  transform: translateY(20%);
  float: none;
}

@media (max-width: 1024px) {
  .section4 .mycontainer .left .index-common-title {
    opacity: 1;
  }
}

@media (max-width: 1024px) {
  .section4 .mycontainer .left .index-common-title {
    transform: translateY(0%);
  }
}

@media (max-width: 767px) {
  .section4 .mycontainer .left .index-common-title {
    float: left;
  }
}

.section4 .mycontainer .left .swiper-btn {
  width: 125px;
  float: none;
  margin-top: 300px;
}

@media (max-width: 767px) {
  .section4 .mycontainer .left .swiper-btn {
    width: 20%;
  }
}

@media (max-width: 767px) {
  .section4 .mycontainer .left .swiper-btn {
    float: right;
  }
}

@media (max-width: 1600px) {
  .section4 .mycontainer .left .swiper-btn {
    margin-top: 252px;
  }
}

@media (max-width: 1366px) {
  .section4 .mycontainer .left .swiper-btn {
    margin-top: 204px;
  }
}

@media (max-width: 1024px) {
  .section4 .mycontainer .left .swiper-btn {
    margin-top: 156px;
  }
}

@media (max-width: 991px) {
  .section4 .mycontainer .left .swiper-btn {
    margin-top: 108px;
  }
}

@media (max-width: 767px) {
  .section4 .mycontainer .left .swiper-btn {
    margin-top: 60px;
  }
}

@media screen and (max-width: 767px) {
  .section4 .mycontainer .left .swiper-btn {
    margin-top: 40px;
  }
}

.section4 .mycontainer .left .swiper-btn .swiper-button-prev, .section4 .mycontainer .left .swiper-btn .swiper-button-next {
  position: static;
  background-image: none;
  width: auto;
  height: auto;
  margin-top: 0;
  transition: all .3s;
  line-height: 1;
}

.section4 .mycontainer .left .swiper-btn .swiper-button-prev::after, .section4 .mycontainer .left .swiper-btn .swiper-button-next::after {
  font-family: 'iconfont';
  color: #0091ff;
  font-size: 36px;
}

@media (max-width: 1600px) {
  .section4 .mycontainer .left .swiper-btn .swiper-button-prev::after, .section4 .mycontainer .left .swiper-btn .swiper-button-next::after {
    font-size: 32.8px;
  }
}

@media (max-width: 1366px) {
  .section4 .mycontainer .left .swiper-btn .swiper-button-prev::after, .section4 .mycontainer .left .swiper-btn .swiper-button-next::after {
    font-size: 29.6px;
  }
}

@media (max-width: 1024px) {
  .section4 .mycontainer .left .swiper-btn .swiper-button-prev::after, .section4 .mycontainer .left .swiper-btn .swiper-button-next::after {
    font-size: 26.4px;
  }
}

@media (max-width: 991px) {
  .section4 .mycontainer .left .swiper-btn .swiper-button-prev::after, .section4 .mycontainer .left .swiper-btn .swiper-button-next::after {
    font-size: 23.2px;
  }
}

@media (max-width: 767px) {
  .section4 .mycontainer .left .swiper-btn .swiper-button-prev::after, .section4 .mycontainer .left .swiper-btn .swiper-button-next::after {
    font-size: 20px;
  }
}

.section4 .mycontainer .left .swiper-btn .swiper-button-prev {
  float: left;
}

.section4 .mycontainer .left .swiper-btn .swiper-button-prev:hover {
  transform: translateX(-5px);
}

.section4 .mycontainer .left .swiper-btn .swiper-button-prev::after {
  content: '\e625';
}

.section4 .mycontainer .left .swiper-btn .swiper-button-next {
  float: right;
}

.section4 .mycontainer .left .swiper-btn .swiper-button-next:hover {
  transform: translateX(5px);
}

.section4 .mycontainer .left .swiper-btn .swiper-button-next::after {
  content: '\e624';
}

.section4 .mycontainer .right {
  width: 80.3%;
  float: right;
}

@media (max-width: 991px) {
  .section4 .mycontainer .right {
    width: 75%;
  }
}

@media (max-width: 767px) {
  .section4 .mycontainer .right {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .section4 .mycontainer .right {
    float: none;
  }
}

.section4 .mycontainer .right .swiper {
  overflow: hidden;
  margin-bottom: 70px;
}

@media (max-width: 1600px) {
  .section4 .mycontainer .right .swiper {
    margin-bottom: 64px;
  }
}

@media (max-width: 1366px) {
  .section4 .mycontainer .right .swiper {
    margin-bottom: 58px;
  }
}

@media (max-width: 1024px) {
  .section4 .mycontainer .right .swiper {
    margin-bottom: 52px;
  }
}

@media (max-width: 991px) {
  .section4 .mycontainer .right .swiper {
    margin-bottom: 46px;
  }
}

@media (max-width: 767px) {
  .section4 .mycontainer .right .swiper {
    margin-bottom: 40px;
  }
}

.section4 .mycontainer .right .swiper .swiper-slide {
  transition: all .6s;
  opacity: 0;
  transform: translateX(10%);
}

@media (max-width: 1024px) {
  .section4 .mycontainer .right .swiper .swiper-slide {
    opacity: 1;
  }
}

@media (max-width: 1024px) {
  .section4 .mycontainer .right .swiper .swiper-slide {
    transform: translateX(0%);
  }
}

.section4 .mycontainer .right .swiper .swiper-slide:hover .item-img b {
  transform: scale(1.05);
}

.section4 .mycontainer .right .swiper .swiper-slide:hover .item-text .title {
  color: #000;
}

.section4 .mycontainer .right .swiper .swiper-slide .item-img {
  position: relative;
  overflow: hidden;
  height: 350px;
  margin-bottom: 25px;
}

@media (max-width: 1600px) {
  .section4 .mycontainer .right .swiper .swiper-slide .item-img {
    height: 320px;
  }
}

@media (max-width: 1366px) {
  .section4 .mycontainer .right .swiper .swiper-slide .item-img {
    height: 250px;
  }
}

@media (max-width: 1024px) {
  .section4 .mycontainer .right .swiper .swiper-slide .item-img {
    height: 200px;
  }
}

@media (max-width: 991px) {
  .section4 .mycontainer .right .swiper .swiper-slide .item-img {
    height: 150px;
  }
}

@media (max-width: 1600px) {
  .section4 .mycontainer .right .swiper .swiper-slide .item-img {
    margin-bottom: 23px;
  }
}

@media (max-width: 1366px) {
  .section4 .mycontainer .right .swiper .swiper-slide .item-img {
    margin-bottom: 21px;
  }
}

@media (max-width: 1024px) {
  .section4 .mycontainer .right .swiper .swiper-slide .item-img {
    margin-bottom: 19px;
  }
}

@media (max-width: 991px) {
  .section4 .mycontainer .right .swiper .swiper-slide .item-img {
    margin-bottom: 17px;
  }
}

@media (max-width: 767px) {
  .section4 .mycontainer .right .swiper .swiper-slide .item-img {
    margin-bottom: 15px;
  }
}

.section4 .mycontainer .right .swiper .swiper-slide .item-img b {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-position: center;
  background-size: cover;
  transition: all .3s;
}

.section4 .mycontainer .right .swiper .swiper-slide .item-text .date {
  color: #0091ff;
  font-size: 20px;
}

@media (max-width: 1600px) {
  .section4 .mycontainer .right .swiper .swiper-slide .item-text .date {
    font-size: 18.8px;
  }
}

@media (max-width: 1366px) {
  .section4 .mycontainer .right .swiper .swiper-slide .item-text .date {
    font-size: 17.6px;
  }
}

@media (max-width: 1024px) {
  .section4 .mycontainer .right .swiper .swiper-slide .item-text .date {
    font-size: 16.4px;
  }
}

@media (max-width: 991px) {
  .section4 .mycontainer .right .swiper .swiper-slide .item-text .date {
    font-size: 15.2px;
  }
}

@media (max-width: 767px) {
  .section4 .mycontainer .right .swiper .swiper-slide .item-text .date {
    font-size: 14px;
  }
}

.section4 .mycontainer .right .swiper .swiper-slide .item-text .title {
  color: #999999;
  line-height: 1.5;
  transition: all .3s;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-size: 24px;
}

@media (max-width: 1600px) {
  .section4 .mycontainer .right .swiper .swiper-slide .item-text .title {
    font-size: 22.4px;
  }
}

@media (max-width: 1366px) {
  .section4 .mycontainer .right .swiper .swiper-slide .item-text .title {
    font-size: 20.8px;
  }
}

@media (max-width: 1024px) {
  .section4 .mycontainer .right .swiper .swiper-slide .item-text .title {
    font-size: 19.2px;
  }
}

@media (max-width: 991px) {
  .section4 .mycontainer .right .swiper .swiper-slide .item-text .title {
    font-size: 17.6px;
  }
}

@media (max-width: 767px) {
  .section4 .mycontainer .right .swiper .swiper-slide .item-text .title {
    font-size: 16px;
  }
}

.section4 .mycontainer .right .swiper-page {
  box-sizing: border-box;
  position: relative;
  padding-left: 44px;
  padding-right: 44px;
}

@media (max-width: 1600px) {
  .section4 .mycontainer .right .swiper-page {
    padding-left: 41.2px;
  }
}

@media (max-width: 1366px) {
  .section4 .mycontainer .right .swiper-page {
    padding-left: 38.4px;
  }
}

@media (max-width: 1024px) {
  .section4 .mycontainer .right .swiper-page {
    padding-left: 35.6px;
  }
}

@media (max-width: 991px) {
  .section4 .mycontainer .right .swiper-page {
    padding-left: 32.8px;
  }
}

@media (max-width: 767px) {
  .section4 .mycontainer .right .swiper-page {
    padding-left: 30px;
  }
}

@media (max-width: 1600px) {
  .section4 .mycontainer .right .swiper-page {
    padding-right: 41.2px;
  }
}

@media (max-width: 1366px) {
  .section4 .mycontainer .right .swiper-page {
    padding-right: 38.4px;
  }
}

@media (max-width: 1024px) {
  .section4 .mycontainer .right .swiper-page {
    padding-right: 35.6px;
  }
}

@media (max-width: 991px) {
  .section4 .mycontainer .right .swiper-page {
    padding-right: 32.8px;
  }
}

@media (max-width: 767px) {
  .section4 .mycontainer .right .swiper-page {
    padding-right: 30px;
  }
}

.section4 .mycontainer .right .swiper-page .swiper-pagination {
  width: 100%;
  height: 1px;
  position: static;
  background: rgba(0, 0, 0, 0.2);
}

.section4 .mycontainer .right .swiper-page .swiper-pagination .swiper-pagination-progressbar-fill {
  height: 2px;
  background: #0295ff;
  margin-top: -1px;
}

.section4 .mycontainer .right .swiper-page .active-num, .section4 .mycontainer .right .swiper-page .total {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #000;
  font-size: 20px;
}

@media (max-width: 1600px) {
  .section4 .mycontainer .right .swiper-page .active-num, .section4 .mycontainer .right .swiper-page .total {
    font-size: 19.2px;
  }
}

@media (max-width: 1366px) {
  .section4 .mycontainer .right .swiper-page .active-num, .section4 .mycontainer .right .swiper-page .total {
    font-size: 18.4px;
  }
}

@media (max-width: 1024px) {
  .section4 .mycontainer .right .swiper-page .active-num, .section4 .mycontainer .right .swiper-page .total {
    font-size: 17.6px;
  }
}

@media (max-width: 991px) {
  .section4 .mycontainer .right .swiper-page .active-num, .section4 .mycontainer .right .swiper-page .total {
    font-size: 16.8px;
  }
}

@media (max-width: 767px) {
  .section4 .mycontainer .right .swiper-page .active-num, .section4 .mycontainer .right .swiper-page .total {
    font-size: 16px;
  }
}

.section4 .mycontainer .right .swiper-page .active-num {
  left: 0;
}

.section4 .mycontainer .right .swiper-page .total {
  right: 0;
  opacity: .3;
}

.index-footer footer {
  height: 100vh;
}

@media (max-width: 1024px) {
  .index-footer footer {
    height: auto;
  }
}

.index-footer footer .footer {
  padding-top: 20vh;
}

@media (max-width: 1366px) {
  .index-footer footer .footer {
    padding-top: 17vh;
  }
}

@media (max-width: 1024px) {
  .index-footer footer .footer {
    padding-top: 60px;
  }
}

@media (max-width: 991px) {
  .index-footer footer .footer {
    padding-top: 40px;
  }
}

@media (max-width: 767px) {
  .index-footer footer .footer {
    padding-top: 30px;
  }
}

.index-footer footer .footer .left-info {
  transition: all 0.6s;
  opacity: 0;
  transform: translateX(-10%);
}

@media (max-width: 1024px) {
  .index-footer footer .footer .left-info {
    transition: none;
  }
}

@media (max-width: 1024px) {
  .index-footer footer .footer .left-info {
    opacity: 1;
  }
}

@media (max-width: 1024px) {
  .index-footer footer .footer .left-info {
    transform: translateX(0%);
  }
}

.index-footer footer .footer .right-nav ul {
  transition: all 0.6s;
  opacity: 0;
  transform: translateX(10%);
}

@media (max-width: 1024px) {
  .index-footer footer .footer .right-nav ul {
    transition: none;
  }
}

@media (max-width: 1024px) {
  .index-footer footer .footer .right-nav ul {
    opacity: 1;
  }
}

@media (max-width: 1024px) {
  .index-footer footer .footer .right-nav ul {
    transform: translateX(0%);
  }
}

.index-footer footer .footer-bottom {
  position: absolute;
}

@media (max-width: 1024px) {
  .index-footer footer .footer-bottom {
    position: static;
  }
}

.index-footer.active footer .footer .left-info {
  transition-delay: .2s;
  opacity: 1;
  transform: translateX(0%);
}

.index-footer.active footer .footer .right-nav ul {
  opacity: 1;
  transform: translateX(0%);
}

.index-footer.active footer .footer .right-nav ul:nth-child(1) {
  transition-delay: .2s;
}

.index-footer.active footer .footer .right-nav ul:nth-child(2) {
  transition-delay: .3s;
}

.index-footer.active footer .footer .right-nav ul:nth-child(3) {
  transition-delay: .4s;
}

.index-footer.active footer .footer .right-nav ul:nth-child(4) {
  transition-delay: .5s;
}

@keyframes circle {
  0% {
    stroke-dashoffset: 151px;
  }
  50% {
    stroke-dashoffset: 75px;
  }
  100% {
    stroke-dashoffset: 0px;
  }
}

@keyframes circle2 {
  0% {
    stroke-dashoffset: 132px;
  }
  50% {
    stroke-dashoffset: 66px;
  }
  100% {
    stroke-dashoffset: 0px;
  }
}

@keyframes circle3 {
  0% {
    stroke-dashoffset: 120px;
  }
  50% {
    stroke-dashoffset: 60px;
  }
  100% {
    stroke-dashoffset: 0px;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
